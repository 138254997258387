import { useCallback } from 'react';
export const useVkPixel = () => {
	const trackVkEvent = useCallback((type: string, goal?: string) => {
		try {
			(window as any)._tmr.push({ id: '3545512', type, goal });
		} catch (e) {
			console.error(e);
		}
	}, []);

	return { trackVkEvent };
};
