export const DISABLED_ACTION_TYPE = ['assistant_call', 'websites_parse', 'if_call', 'map_call'];

export const METHOD_OPTIONS = [
	{
		label: 'GET',
		value: 'get'
	},
	{
		label: 'POST',
		value: 'post'
	},
	{
		label: 'PATCH',
		value: 'patch'
	},
	{
		label: 'PUT',
		value: 'put'
	},
	{
		label: 'DELETE',
		value: 'delete'
	}
];

export const PLUGIN_OPTIONS = [
	{
		label: 'Browser',
		value: 'browser'
	},
	{
		label: '1C',
		value: '1c'
	}
];

export const TAGS_MAX_COUNT = 3;
