import { Element, Root } from 'hast';
import { Plugin } from 'unified';
import { visitParents } from 'unist-util-visit-parents';

const remarkBase64ToImg: Plugin<[], Root> = () => {
	return (ast, file) => {
		visitParents(ast, 'element', (node, ancestors) => {
			if (node.type === 'element' && node.tagName === 'img' && typeof node.properties?.src === 'string') {
				const originalSrc = node.properties.src;

				const isBase64 = originalSrc.startsWith('data:image/');
				const base64String = isBase64 ? originalSrc : `data:image/png;base64,${originalSrc}`;

				if (!base64String) {
					console.error('Base64 string is empty or invalid:', { originalSrc });
					return;
				}

				try {
					const parent = ancestors.at(-1)!;
					const img: Element = {
						type: 'element',
						tagName: 'img',
						properties: {
							src: base64String,
							alt: node.properties.alt || 'Image'
						},
						children: []
					};

					const nodeIndex = parent.children.indexOf(node);
					if (nodeIndex === -1) {
						console.error('Node not found in parent:', { parent, node });
						return;
					}

					parent.children[nodeIndex] = img;
				} catch (error) {
					console.error('Error processing base64 string:', {
						originalSrc,
						base64String,
						error
					});
				}
			}
		});
	};
};

export default remarkBase64ToImg;
