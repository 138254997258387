import React from 'react';
import { GptzatorAvatar } from '../../../lib/getIcon';

export const MessageLoader = () => {
	return (
		<div className='flex items-center relative rounded-xl bg-gray-bg p-4'>
			<div className='min-w-[32px] w-[32px] h-[32px] rounded-full flex items-center justify-center overflow-hidden mr-4'>
				<GptzatorAvatar />
			</div>
			<div className='flex space-x-2 animate-pulse'>
				<div className='w-3 h-3 bg-gray-muted rounded-full'></div>
				<div className='w-3 h-3 bg-gray-muted rounded-full'></div>
				<div className='w-3 h-3 bg-gray-muted rounded-full'></div>
			</div>
		</div>
	);
};
