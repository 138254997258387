export const selectUser = (state: RootState) => {
	return state.user?.user;
};

export const isLoggedIn = (state: RootState) => {
	return state.user?.user?.type === 'registered';
};

export const isInsufficientBalance = (state: RootState) => {
	return !!state.user?.user?.balance && Number(state.user?.user?.balance) <= 0;
};

export const selectUserOauthClients = (state: RootState) => {
	return state.user?.user?.meta?.oauth;
};
