import React, { memo } from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from 'shared/ui/Icons';
import { OnNodesDelete, Node } from 'reactflow';
import { TActionNode } from '../../model/types';
import { useTranslate } from 'shared/lib/i18n';

interface IDeleteActionButton {
	action: TActionNode;
	onNodesDelete: OnNodesDelete;
}

const DeleteActionButtonComponent = ({ action, onNodesDelete }: IDeleteActionButton) => {
	const t = useTranslate();
	const deleteActionHandler = (action: Node) => {
		onNodesDelete([action]);
	};

	return (
		<Popconfirm
			title={<span>{t('delete_step')}?</span>}
			onConfirm={() => deleteActionHandler(action)}
			cancelButtonProps={{ type: 'text' }}
			okText={t('delete')}
			cancelText={t('cancel')}
		>
			<Button
				type='text'
				shape='circle'
				icon={<DeleteOutlined />}
				className='hover:bg-white opacity-25 ml-auto'
			></Button>
		</Popconfirm>
	);
};

export const DeleteActionButton = memo(DeleteActionButtonComponent);
