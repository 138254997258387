import { memo } from 'react';
import { Empty } from '../../../lib/getIcon';

export const EmptyList = memo((props: { message?: string }) => {
	return (
		<div className='w-full text-center flex flex-col grow justify-center items-center gap-y-5'>
			<Empty />
			{props.message && <p className='max-w-[280px] text-sm opacity-70'>{props.message}</p>}
		</div>
	);
});
