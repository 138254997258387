import React, { memo, useEffect, useState } from 'react';
import { Button, Form, FormInstance, message, Upload, UploadProps } from 'antd';
import { CloseOutlined, UploadOutlined } from 'shared/ui/Icons';
import { TUserInputField } from '../../model/types';
import { useTranslate } from 'shared/lib/i18n';
import { useDeleteFileMutation, useUploadFileMutation } from 'entities/files';

interface IAttachFilesFormField {
	field: TUserInputField;
	value: string[];
	disabled: boolean;
	handleFilesChange?: (name: string, value: string[]) => void;
	form: FormInstance;
}
const AttachFilesFormFieldComponent = ({ field, value, disabled, handleFilesChange, form }: IAttachFilesFormField) => {
	const t = useTranslate();
	const [files, setFiles] = useState<{ uid: string; name: string }[]>([]);

	const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();
	const [deleteFile, { isLoading }] = useDeleteFileMutation();
	// const { currentFiles } = useGetFilesByIdsQuery(value, {
	// 	selectFromResult: ({ data }) => {
	// 		return { currentFiles: data?.docs ?? [] };
	// 	},
	// 	refetchOnMountOrArgChange: true
	// });
	const formFieldItem = Form.useWatch(field.name, form);
	useEffect(() => {
		if (!formFieldItem) {
			setFiles([]);
		}
	}, [formFieldItem]);

	const handleRemoveFile = async (uid: string) => {
		await deleteFile({ fileId: uid });
		setFiles((prevFiles) => prevFiles.filter((file) => file.uid !== uid));
	};

	const beforeUpload: Required<UploadProps>['beforeUpload'] = (file) => {
		const fileType = file.name.split('.').pop()?.toLowerCase();
		const isAllowedType = fileType ? field.allowedTypes?.includes(fileType) : false;
		const allowedSize = field.maxSize ? file.size / 1024 / 1024 < field.maxSize : true;

		if (!isAllowedType) {
			message.error({ content: `${file.name}: ${t('unsupported_file_format')} `, duration: 7 });
		}

		if (!allowedSize) {
			message.error({ content: `${file.name}: ${t('file_size_warning')}`, duration: 7 });
		}

		return isAllowedType && allowedSize;
	};

	const handleFileUpload: Required<UploadProps>['customRequest'] = async ({ file, onSuccess, onError }) => {
		if (!(file instanceof File)) {
			message.error({ content: <>{t('loading_file_error')}</>, duration: 7 });
			return onError?.(
				{
					name: 'error',
					message: 'Error'
				},
				<>{t('loading_file_error')}</>
			);
		}

		try {
			const formData = new FormData();
			formData.append('file', file);
			const res = await uploadFile({ formData }).unwrap();
			setFiles((prevFiles) => [...prevFiles, { name: file.name, uid: res.doc.id }]);
			return onSuccess?.('res.doc.id');
		} catch (e) {
			message.error({ content: <>{t('loading_file_error')}</>, duration: 7 });
			return onError?.(
				{
					name: 'error',
					message: 'Error'
				},
				<>{t('loading_file_error')}</>
			);
		}
	};

	useEffect(() => {
		if (files.length) {
			handleFilesChange?.(
				field.name,
				files.map((file) => file.uid)
			);
		}
	}, [files]);

	return (
		<Form.Item
			key={field.name}
			name={field.name}
			label={
				<div className='text-start opacity-65 max-w-[185px] min-w-[185px] text-ellipsis overflow-hidden'>
					<span className='max-w-full whitespace-normal '>{field.label}</span>
					{field.required && <span className='ml-1 text-error'>*</span>}
				</div>
			}
			rules={[{ required: field.required, message: t('error.required_field') }]}
		>
			<div>
				<Upload
					multiple={field.isMultiple}
					maxCount={10}
					beforeUpload={beforeUpload}
					customRequest={handleFileUpload}
					showUploadList={false}
					name={field.name}
					fileList={files}
					disabled={disabled}
				>
					<Button
						type='text'
						className='!bg-white border border-solid border-gray-border-dark'
						disabled={isUploading || isLoading || disabled}
						icon={<UploadOutlined className='!text-sm' />}
					>
						Загрузить файлы
					</Button>
				</Upload>
				{!!files.length && (
					<div className='flex flex-wrap gap-1.5 mt-3'>
						{files.map((file) => {
							return (
								<div key={file.uid} className='w-[192px] max-w-[192px] rounded-xl bg-gray-bg-hover p-3 pr-9 relative'>
									<div className='flex'>
										<div className='overflow-hidden text-ellipsis'>
											<span className='opacity-70 text-xs leading-5 font-medium whitespace-nowrap'>{file.name}</span>
										</div>
										{!disabled && (
											<Button
												type='link'
												icon={<CloseOutlined className='' />}
												onClick={() => handleRemoveFile(file.uid)}
												className='absolute right-0 top-1.5 text-black hover:!text-black opacity-[0.45] hover:opacity-60'
											/>
										)}
									</div>
									<p className='uppercase opacity-25 text-xs leading-5'>{file.name.split('.').pop()}</p>
								</div>
							);
						})}
					</div>
				)}
				{/* {!!currentFiles.length && disabled && !files.length && (
					<div className='flex flex-wrap gap-3 mt-3'>
						{currentFiles.map((file) => {
							return (
								<div key={file.id} className='w-[192px] max-w-[192px] rounded-xl bg-gray-bg-hover p-3 pr-9 relative'>
									<div className='flex'>
										<div className='overflow-hidden text-ellipsis'>
											<span className='opacity-70 text-xs leading-5 font-medium whitespace-nowrap'>
												{file.filename}
											</span>
										</div>
									</div>
									<p className='uppercase opacity-25 text-xs leading-5'>{file.filename.split('.').pop()}</p>
								</div>
							);
						})}
					</div>
				)} */}
			</div>
		</Form.Item>
	);
};

export const AttachFilesFormField = memo(AttachFilesFormFieldComponent);
