import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';

export const formatDate = (inputDate: string) => {
	const dateObject = new Date(inputDate);
	return dateObject.toLocaleDateString('ru-RU', { year: 'numeric', month: 'long', day: 'numeric' });
};

export const shortMonthFormatDate = (isoDate: string) => {
	dayjs.locale(ru);
	const formattedDate = dayjs(isoDate).format('DD MMM YYYY');
	return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

export const longMonthFormatDate = (isoDate: string) => {
	dayjs.locale(ru);
	const formattedDate = dayjs(isoDate).format('DD MMMM YYYY');
	return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

export const longMonthAndTimeDate = (isoDate: string): string => {
	dayjs.locale(ru);
	const formattedDate = dayjs(isoDate).format('DD MMMM YYYY, HH:mm');
	return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};
