import React, { memo, useMemo } from 'react';
import { Divider, Form, Input, InputNumber, Select, Switch } from 'antd';
import { EAppFormFields, TUserInputItemFields } from '../../../../model/types';
import { DeleteOutlined } from 'shared/ui/Icons';
import { validateNotEmpty } from 'shared/lib';
import { useTranslate } from 'shared/lib/i18n';

const FileFieldsComponent = ({ action, onChange, path, handleRemoveFields, index }: TUserInputItemFields) => {
	const t = useTranslate();
	const fileTypes = useMemo(
		() => [
			{ label: 'pdf', value: 'pdf' },
			{ label: 'csv', value: 'csv' },
			{ label: 'docx', value: 'docx' },
			{ label: 'txt', value: 'txt' },
			{ label: 'wav', value: 'wav' },
			{ label: 'mp3', value: 'mp3' }
		],
		[]
	);
	return (
		<div>
			<Divider style={{ margin: '8px 0' }} />
			<div className='flex w-full justify-between'>
				<div>{t('input.file')}</div>
				<DeleteOutlined
					className='text-error'
					onClick={() => {
						handleRemoveFields(index);
					}}
				/>
			</div>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.BLOCK_TYPE] ?? 'attachedFiles'}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={t('key_name')}
				name={[...path, EAppFormFields.NAME]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.NAME]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') },
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={t('label')}
				name={[...path, EAppFormFields.LABEL]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.LABEL]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') },
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.LABEL], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('max_size')}</span>}
				name={[...path, EAppFormFields.MAX_SIZE]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.MAX_SIZE]}
				rules={[]}
			>
				<InputNumber
					min='0'
					onChange={(value) => onChange([...path, `${index}`, EAppFormFields.MAX_SIZE], value)}
					className='w-full'
				/>
			</Form.Item>
			<Form.Item
				label={<span>{t('allowed_types')}</span>}
				name={[...path, EAppFormFields.ALLOWED_TYPES]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.ALLOWED_TYPES]}
				rules={[]}
			>
				<Select
					mode='multiple'
					allowClear
					style={{ width: '100%' }}
					options={fileTypes}
					onChange={(value) => onChange([...path, EAppFormFields.ALLOWED_TYPES], value)}
				/>
			</Form.Item>
			<Form.Item
				label={<span>{t('required')}</span>}
				name={[...path, EAppFormFields.REQUIRED]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.REQUIRED]}
			>
				<Switch onChange={(e) => onChange([...path, `${index}`, EAppFormFields.REQUIRED], e)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('multiple_files')}</span>}
				name={[...path, EAppFormFields.IS_MULTIPLE]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.IS_MULTIPLE]}
			>
				<Switch onChange={(e) => onChange([...path, `${index}`, EAppFormFields.IS_MULTIPLE], e)} />
			</Form.Item>
		</div>
	);
};

export const FileFields = memo(FileFieldsComponent);
