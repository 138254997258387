const typeTemplate = 'Field "${label}" is not a valid "${type}"';
const len = 'Field "${label}" must be ${len} characters';
const min = 'Field "${label}" must be at least ${min} characters';
const max = 'Field "${label}" must be up to ${max} characters';
const range = 'Field "${label}" must be between ${min}-${max} characters';

export const validateEnMessages = {
	required: '${label} cannot be empty',
	string: {
		len,
		min,
		max,
		range
	},
	number: {
		len,
		min,
		max,
		range
	},
	array: {
		len,
		min,
		max,
		range
	},
	types: {
		string: typeTemplate,
		method: typeTemplate,
		array: typeTemplate,
		object: typeTemplate,
		number: typeTemplate,
		date: typeTemplate,
		boolean: typeTemplate,
		integer: typeTemplate,
		float: typeTemplate,
		regexp: typeTemplate,
		email: typeTemplate,
		url: typeTemplate,
		hex: typeTemplate
	}
};
