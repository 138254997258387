import { memo } from 'react';
import { ConfigProvider, Popover, PopoverProps } from 'antd';

const CustomPopoverComponent = ({ children, ...props }: PopoverProps) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Popover: {
						paddingSM: 0,
						paddingXS: 0
					}
				}
			}}
		>
			<Popover {...props}>{children}</Popover>
		</ConfigProvider>
	);
};

export const CustomPopover = memo(CustomPopoverComponent);
