import { Link, useNavigate } from 'react-router-dom';
import cx from './AppCard.module.css';
import cn from 'classnames';

import { memo, useCallback, MouseEvent, FC, useMemo } from 'react';
import { useDeleteAppMutation, useGetTagsQuery, useUpdateIsFavouriteMutation } from 'entities/apps';
import { TApp } from '../model/types';
import { LockIcon, ThreeDotsIcon, StarFilled, DeleteOutlined, EditOutlinedIcon, SmallLogoIcon } from 'shared/ui/Icons';
import { useTranslate } from 'shared/lib/i18n';
import { methodDebounce, useMobile } from 'shared/lib';
import { Dropdown, MenuProps, Popconfirm } from 'antd';

type TAppCard = {
	app: TApp;
	isLoggedIn: boolean;
	isMine: boolean;
};

const AppCardComponent: FC<TAppCard> = memo(({ app, isLoggedIn, isMine }) => {
	const { name, descriptionSmall, id, isFavourite, isPrivate, tags } = app;
	const isMobile = useMobile();
	const t = useTranslate();
	const navigate = useNavigate();
	const [updateIsFavourite, { isLoading }] = useUpdateIsFavouriteMutation();
	const [deleteApp, { isLoading: isDeleting }] = useDeleteAppMutation();
	const { tagsData } = useGetTagsQuery(undefined, {
		selectFromResult: ({ data }) => {
			return { tagsData: data?.docs.filter((tag) => tags?.includes(tag.id)) ?? [] };
		}
	});
	const onFavouriteClick = async (e: MouseEvent<HTMLSpanElement>) => {
		if (isLoading || isDeleting || !id) return;
		updateIsFavourite({ id, isFavourite: !isFavourite });
	};

	const items: MenuProps['items'] = useMemo(
		() => [
			...(!isMobile
				? [
						{
							label: (
								<a
									onClick={(e) => {
										e.preventDefault();
										navigate(`/apps/${app.id}/edit`);
									}}
									className={cn({ 'pointer-events-none': isDeleting })}
								>
									<EditOutlinedIcon />
									<span className='ml-2'>{t('edit')}</span>
								</a>
							),
							key: '0'
						}
				  ]
				: []),
			{
				label: (
					<Popconfirm
						title={<span>{t('delete_app.request_confirm')}?</span>}
						onConfirm={(e) => {
							e?.preventDefault();
							e?.stopPropagation();
							deleteApp(app.id!);
						}}
						onCancel={(e) => {
							e?.preventDefault();
							e?.stopPropagation();
						}}
						arrow={false}
						cancelButtonProps={{ type: 'text' }}
						okText={t('delete')}
						cancelText={t('cancel')}
					>
						<a
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<DeleteOutlined className='text-error' />
							<span className='text-error ml-2'>{t('delete')}</span>
						</a>
					</Popconfirm>
				),
				key: '1'
			}
		],
		[isMobile]
	);

	const debouncedFavouriteClick: (...args: Parameters<typeof onFavouriteClick>) => void = useCallback(
		methodDebounce((data: { e: MouseEvent<HTMLSpanElement> }) => onFavouriteClick(data.e), 300),
		[onFavouriteClick]
	);

	return (
		<Link key={id} to={`/apps/${id}`}>
			<div className={cx.root}>
				<div className='flex justify-between items-start'>
					<div className='w-[48px] h-[48px] min-w-[48px] max-h-[48px] rounded-full bg-fill-tertiary flex items-center justify-center overflow-hidden mb-8'>
						{app.emoji ?? <SmallLogoIcon className='text-2xl text-primary-active' />}
					</div>
					{!!tagsData.length && (
						<div className='flex flex-wrap justify-end gap-1'>
							{tagsData.map(
								(tag) =>
									tag.name && (
										<span
											key={tag.id}
											className='text-xs px-2 py-1 rounded-full border-solid border border-gray-border whitespace-nowrap'
										>
											{tag.name}
										</span>
									)
							)}
						</div>
					)}
				</div>
				<div className='overflow-y-auto'>
					<h4 className='text-base font-medium m-0 mb-2.5'>{name}</h4>
					<p className='text-gray-secondry text-sm m-0'>{descriptionSmall || 'Отличный сервис'}</p>
				</div>
				{isLoggedIn && (
					<div className='mt-auto w-full flex justify-end'>
						{isPrivate && (
							<span className='mr-auto text-xl'>
								<LockIcon />
								<span className={cx.private}>{t('private')}</span>
							</span>
						)}
						<StarFilled
							className={cn(cx.favourite, {
								'text-black !opacity-[0.15]': !isFavourite,
								'text-yellow-400 opacity-100 text-xl': isFavourite
							})}
							onClick={(e) => {
								e.preventDefault();
								debouncedFavouriteClick(e);
							}}
						/>
						{isMine && (
							<Dropdown menu={{ items }} trigger={['click']}>
								<span onClick={(e) => e.preventDefault()} className='flex items-center'>
									<ThreeDotsIcon className='ml-2' />
								</span>
							</Dropdown>
						)}
					</div>
				)}
			</div>
		</Link>
	);
});

export const AppCard = memo(AppCardComponent);
