import { memo } from 'react';
import { ConfigProvider, Collapse, CollapseProps } from 'antd';

const CustomCollapseComponent = (props: CollapseProps) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Collapse: {
						contentPadding: 0,
						headerPadding: 0
					}
				}
			}}
		>
			<Collapse {...props} />
		</ConfigProvider>
	);
};

export const CustomCollapse = memo(CustomCollapseComponent);
