import { components } from './themeComponents';

import type { ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
	components,
	token: {
		colorPrimaryBg: '#CCFFCC',
		colorPrimaryBgHover: '#E0FFE0',
		colorPrimaryBorder: '#CCFFCC',
		colorPrimaryBorderHover: '#B2FFB7',
		colorPrimaryHover: '#A3FFAB',
		colorPrimary: '#89F595',
		colorPrimaryActive: '#67E491',
		colorPrimaryTextHover: '#67E491',
		colorPrimaryText: '#45C16F',
		colorPrimaryTextActive: '#3BA960',
		colorTextBase: '#1B2631',
		fontFamily: 'Manrope'
	}
};
