import React, { memo, useRef } from 'react';
import { EAppFormFields, TAppFormFields } from '../../../model/types';
import { Checkbox, Form, Input, Select, Switch } from 'antd';
import { LabelWithInfo } from 'shared/ui/LabelWithInfo';
import { validateNotEmpty } from 'shared/lib';
import { useTranslate } from 'shared/lib/i18n';
import { useAppSelector } from 'shared/model';
import { selectSlugs } from '../../../model/selectors';

const MailingCallFieldsComponent = ({
	action,
	title,
	actionsSlot,
	onChange,
	actionIndex,
	form,
	path
}: TAppFormFields) => {
	const t = useTranslate();
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

	const slugs = useAppSelector(selectSlugs);

	const handleVariableInsert = (path: string[], variable: string, prompt: any) => {
		if (textAreaRef.current) {
			const { selectionStart, selectionEnd } = textAreaRef.current;
			const newPrompt = prompt
				? `${prompt.substring(0, selectionStart)}{{ ${variable} }}${prompt.substring(selectionEnd)}`
				: `{{ ${variable} }}`;
			form?.setFieldValue(path, newPrompt);
			onChange(path, newPrompt);
			setTimeout(() => {
				if (textAreaRef.current) {
					textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd = selectionStart + variable.length + 6;
					textAreaRef.current.focus();
				}
			}, 0);
		}
	};

	return (
		<div className={action.selected ? '' : 'hidden'}>
			<div className='flex justify-between text-base font-medium'>
				{title}
				{actionsSlot}
			</div>
			<Form.Item
				label={EAppFormFields.NEXT_SLUG}
				name={[...path, EAppFormFields.NEXT_SLUG]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.BLOCK_TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
				name={[...path, EAppFormFields.BLOCK_NAME]}
				initialValue={action.data[EAppFormFields.BLOCK_NAME]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('description')}</span>}
				name={[...path, EAppFormFields.DESCRIPTION]}
				initialValue={action.data[EAppFormFields.DESCRIPTION]}
				rules={[
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 2, maxRows: 4 }}
					onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
				/>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('dynamic_name')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('dynamic_name.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.SLUG]}
				initialValue={action.data[EAppFormFields.SLUG]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('shipping_method')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('shipping_method.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.TYPES]}
				initialValue={[
					action.data[EAppFormFields.TYPES].telegram ? 'telegram' : null,
					action.data[EAppFormFields.TYPES].email ? 'email' : null
				]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
			>
				<Checkbox.Group
					onChange={(e) => {
						onChange([...path, EAppFormFields.TYPES], {
							telegram: e.includes('telegram'),
							email: e.includes('email')
						});
					}}
				>
					<Checkbox value='telegram'>Telegram</Checkbox>
					<Checkbox value='email'>Email</Checkbox>
				</Checkbox.Group>
			</Form.Item>
			<Form.Item
				label={
					<span>
						{t('to')}
						<span className='ml-1 text-error'>*</span>
					</span>
				}
				name={[...path, EAppFormFields.EMAILS]}
				initialValue={action.data[EAppFormFields.EMAILS]}
				rules={[{ required: true, message: t('error.required_field') }]}
			>
				<Select
					mode='tags'
					tokenSeparators={[',']}
					onChange={(value) => {
						onChange([...path, EAppFormFields.EMAILS], value);
					}}
				></Select>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('message')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('message.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.MESSAGE]}
				initialValue={action.data[EAppFormFields.MESSAGE]}
				className='mb-2'
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},

					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 4 }}
					ref={(input) => (textAreaRef.current = input?.resizableTextArea?.textArea || null)}
					rows={4}
					onChange={(e) => onChange([...path, EAppFormFields.MESSAGE], e.target.value)}
				/>
			</Form.Item>
			<div className='flex flex-wrap gap-x-2.5 gap-y-1.5 mb-4'>
				{slugs?.slice(0, actionIndex).map((slug) => (
					<div
						key={slug}
						className='cursor-pointer text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'
						onClick={() =>
							handleVariableInsert([...path, EAppFormFields.MESSAGE], slug, action.data[EAppFormFields.MESSAGE])
						}
					>
						{slug}
					</div>
				))}
			</div>
			<Form.Item name={[...path, EAppFormFields.AUTONEXT]} initialValue={true} rules={[]} hidden={true}>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={<span>{t('visibility')}</span>}
						tooltipText={t('visibility.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.IS_HIDDEN]}
				initialValue={action.data[EAppFormFields.IS_HIDDEN]}
				rules={[]}
				hidden={true}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
			</Form.Item>
		</div>
	);
};

export const MailingCallFields = memo(MailingCallFieldsComponent);
