import { useCallback } from 'react';

interface IEventData {
	[key: string]: any;
}
export const useCarrotQuest = () => {
	const trackEvent = useCallback((eventName: string, eventData?: IEventData) => {
		try {
			(window as any).carrotquest.track(eventName, eventData);
		} catch (e) {
			console.error(e);
		}
	}, []);

	return { trackEvent };
};
