import React, { memo, useRef } from 'react';
import { EAppFormFields, TAppFormFields } from '../../../model/types';
import { Form, Input, InputNumber, Radio, Select, Switch } from 'antd';
import { LabelWithInfo } from 'shared/ui/LabelWithInfo';
import { validateNotEmpty } from 'shared/lib';
import { CustomCollapse } from 'shared/ui';
import { MinusCircleOutlined, PlusCircleOutlined } from 'shared/ui/Icons';
import { useTranslate } from 'shared/lib/i18n';
import { useGetModelsQuery } from 'entities/models';
import { useResultViewOptions } from '../../../lib/useResultViewOptions';
import { useAppSelector } from 'shared/model';
import { selectSlugs } from '../../../model/selectors';

const GptCallFieldsComponent = ({ action, title, actionsSlot, onChange, actionIndex, form, path }: TAppFormFields) => {
	const t = useTranslate();
	const resultViewOptions = useResultViewOptions();
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

	const slugs = useAppSelector(selectSlugs);
	const { models } = useGetModelsQuery(undefined, {
		selectFromResult: ({ data }): { models: { value: string; label: string }[] } => {
			return {
				models:
					data
						?.filter((model) => model.vendor === 'openai')
						.map((model) => ({ value: model.codeId, label: model.name })) ?? []
			};
		}
	});

	const handleVariableInsert = (path: string[], variable: string, prompt: any) => {
		if (textAreaRef.current) {
			const { selectionStart, selectionEnd } = textAreaRef.current;
			const newPrompt = prompt
				? `${prompt.substring(0, selectionStart)}{{ ${variable} }}${prompt.substring(selectionEnd)}`
				: `{{ ${variable} }}`;
			form?.setFieldValue(path, newPrompt);
			onChange(path, newPrompt);
			setTimeout(() => {
				if (textAreaRef.current) {
					textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd = selectionStart + variable.length + 6;
					textAreaRef.current.focus();
				}
			}, 0);
		}
	};

	return (
		<div className={action.selected ? '' : 'hidden'}>
			<div className='flex justify-between text-base font-medium'>
				{title}
				{actionsSlot}
			</div>
			<Form.Item
				label={EAppFormFields.NEXT_SLUG}
				name={[...path, EAppFormFields.NEXT_SLUG]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.BLOCK_TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
				name={[...path, EAppFormFields.BLOCK_NAME]}
				initialValue={action.data[EAppFormFields.BLOCK_NAME]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('dynamic_name')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('dynamic_name.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.SLUG]}
				initialValue={action.data[EAppFormFields.SLUG]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('description')}</span>}
				name={[...path, EAppFormFields.DESCRIPTION]}
				initialValue={action.data[EAppFormFields.DESCRIPTION]}
				rules={[
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 2, maxRows: 4 }}
					onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
				/>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('result_view')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('result_view.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.TYPE]}
				initialValue={action.data[EAppFormFields.TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
			>
				<Radio.Group onChange={(e) => onChange([...path, EAppFormFields.TYPE], e.target.value)}>
					{resultViewOptions.map((option) => (
						<Radio key={`${option.label}_${option.value}`} value={option.value}>
							{option.label}
						</Radio>
					))}
				</Radio.Group>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('prompt')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('prompt.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.PROMPT]}
				initialValue={action.data[EAppFormFields.PROMPT]}
				className='mb-2'
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},

					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 4 }}
					ref={(input) => (textAreaRef.current = input?.resizableTextArea?.textArea || null)}
					onChange={(e) => onChange([...path, EAppFormFields.PROMPT], e.target.value)}
				/>
			</Form.Item>
			<div className='flex flex-wrap gap-x-2.5 gap-y-1.5 mb-4'>
				{slugs?.slice(0, actionIndex).map((slug) => (
					<div
						key={slug}
						className='cursor-pointer text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'
						onClick={() =>
							handleVariableInsert([...path, EAppFormFields.PROMPT], slug, action.data[EAppFormFields.PROMPT])
						}
					>
						{slug}
					</div>
				))}
			</div>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('model')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('model.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.MODEL]}
				initialValue={action.data[EAppFormFields.MODEL]}
				rules={[{ required: true, message: t('error.required_field') }]}
			>
				<Select onChange={(value) => onChange([...path, EAppFormFields.MODEL], value)} options={models}></Select>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={<span>{t('visibility')}</span>}
						tooltipText={t('visibility.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.IS_HIDDEN]}
				initialValue={action.data[EAppFormFields.IS_HIDDEN]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo label={<span>{t('autonext')}</span>} tooltipText={t('autonext.description')}></LabelWithInfo>
				}
				name={[...path, EAppFormFields.AUTONEXT]}
				initialValue={action.data[EAppFormFields.AUTONEXT]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
			</Form.Item>
			<CustomCollapse
				className='mt-3'
				ghost
				expandIcon={(panelProps) => {
					return panelProps.isActive ? (
						<MinusCircleOutlined className='!text-base !font-bold opacity-60' />
					) : (
						<PlusCircleOutlined className='!text-base !font-bold opacity-60' />
					);
				}}
				items={[
					{
						label: (
							<div>
								<span className='font-bold opacity-60'>{t('advanced')}</span>
								<span className='ml-1 font-bold opacity-60 lowercase'>{t('settings')}</span>
							</div>
						),
						children: (
							<>
								<Form.Item
									label={<span>{t('max_tokens')}</span>}
									name={[...path, EAppFormFields.MAX_TOKENS]}
									initialValue={action.data[EAppFormFields.MAX_TOKENS]}
									rules={[]}
								>
									<InputNumber
										min='0'
										onChange={(value) => onChange([...path, EAppFormFields.MAX_TOKENS], value)}
										className='w-full'
									/>
								</Form.Item>
								<Form.Item
									label={<span>{t('extract_images')}</span>}
									name={[...path, EAppFormFields.EXTRACT_IMAGES]}
									initialValue={action.data[EAppFormFields.EXTRACT_IMAGES]}
									rules={[]}
								>
									<Switch onChange={(e) => onChange([...path, EAppFormFields.EXTRACT_IMAGES], e)} />
								</Form.Item>
								<Form.Item
									label={
										<span>
											{t('content_conversation_type')}
											<span className='ml-1 text-error'>*</span>
										</span>
									}
									name={[...path, EAppFormFields.CONTENT_CONVERSATION_TYPE]}
									initialValue={action.data[EAppFormFields.CONTENT_CONVERSATION_TYPE]}
									rules={[{ required: true, message: t('error.required_field') }]}
								>
									<Select
										onChange={(value) => onChange([...path, EAppFormFields.CONTENT_CONVERSATION_TYPE], value)}
										options={[
											{
												label: 'Not converted',
												value: 'notConverted'
											},
											{
												label: 'to JSON',
												value: 'toJson'
											}
										]}
									></Select>
								</Form.Item>
								<Form.Item
									label={<span>{t('temperature')}</span>}
									name={[...path, EAppFormFields.TEMPERATURE]}
									initialValue={`${action.data[EAppFormFields.TEMPERATURE]}`}
									rules={[]}
								>
									<InputNumber
										min='0.1'
										max='1'
										step='0.1'
										onChange={(value) => onChange([...path, EAppFormFields.TEMPERATURE], value)}
										className='w-full'
										stringMode
									/>
								</Form.Item>
							</>
						)
					}
				]}
			></CustomCollapse>
		</div>
	);
};

export const GptCallFields = memo(GptCallFieldsComponent);
