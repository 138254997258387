import React, { useRef, useEffect, ChangeEvent } from 'react';
import { Input as AntdInput, InputProps, InputRef } from 'antd';
import IMask, { InputMask, FactoryArg } from 'imask';

interface InputMaskProps extends Omit<InputProps, 'onChange'> {
	maskOptions: FactoryArg;
	value?: string;
	onChange?: (e?: React.ChangeEvent<HTMLInputElement> | string) => void;
}

export const InputMasked: React.FC<InputMaskProps> = ({ maskOptions, value, onChange, ...props }) => {
	const inputRef = useRef<InputRef>(null);
	const maskRef = useRef<InputMask<FactoryArg> | null>(null);

	useEffect(() => {
		if (inputRef.current && inputRef.current.input) {
			maskRef.current = IMask(inputRef.current.input, maskOptions);

			if (onChange && maskRef.current) {
				maskRef.current.on('accept', () => onChange(maskRef.current?.value));
			}
		}

		return () => {
			if (maskRef.current) {
				maskRef.current.destroy();
				maskRef.current = null;
			}
		};
	}, [maskOptions, onChange]);

	useEffect(() => {
		if (maskRef.current && value !== undefined) {
			maskRef.current.value = value;
		}
	}, [value]);

	return <AntdInput ref={inputRef} value={value} onChange={onChange} {...props} />;
};
