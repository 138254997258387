import { memo } from 'react';
import { TUserInputFieldsTypes } from '../../../../model/types';
import { useTranslate } from 'shared/lib/i18n';

interface IInputTypeSelector {
	handleTypeSelect: (type: TUserInputFieldsTypes) => void;
}

const InputTypeSelectorComponent = ({ handleTypeSelect }: IInputTypeSelector) => {
	const t = useTranslate();
	return (
		<div className='min-w-[220px] pt-3'>
			<div
				onClick={(e) => {
					e.preventDefault();
					handleTypeSelect('title');
				}}
				className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
			>
				<span>{t('input.title')}</span>
			</div>
			<div
				onClick={(e) => {
					e.preventDefault();
					handleTypeSelect('text');
				}}
				className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
			>
				<span>{t('input.text')}</span>
			</div>
			<div
				onClick={(e) => {
					e.preventDefault();
					handleTypeSelect('textarea');
				}}
				className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
			>
				<span>{t('input.textarea')}</span>
			</div>
			<div
				onClick={(e) => {
					e.preventDefault();
					handleTypeSelect('checkbox');
				}}
				className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
			>
				<span>{t('input.checkbox')}</span>
			</div>
			<div
				onClick={(e) => {
					e.preventDefault();
					handleTypeSelect('select');
				}}
				className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
			>
				<span>{t('input.select')}</span>
			</div>
			<div
				onClick={(e) => {
					e.preventDefault();
					handleTypeSelect('attachedFiles');
				}}
				className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
			>
				<span>{t('input.file')}</span>
			</div>
		</div>
	);
};

export const InputTypeSelector = memo(InputTypeSelectorComponent);
