import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type TAppSettinsState = {
	isMenuOpen: boolean;
};

const initialState: TAppSettinsState = {
	isMenuOpen: false
};

export const appSettingsSlice = createSlice({
	name: 'appSettings',
	initialState,
	reducers: {
		setMenuOpen: (state, action: PayloadAction<boolean>) => {
			state.isMenuOpen = action.payload;
		}
	}
});

export const { setMenuOpen } = appSettingsSlice.actions;
