import { useMemo } from 'react';
import { useTranslate } from 'shared/lib/i18n';

export const useResultViewOptions = () => {
	const t = useTranslate();
	return useMemo(
		() => [
			{
				label: t('dynamic_text'),
				value: 'dynamic_text'
			},
			{
				label: t('static_text'),
				value: 'static_text'
			},
			{
				label: t('choice'),
				value: 'choice'
			}
		],
		[t]
	);
};
