import { memo } from 'react';
import { useTranslate } from '../../../../shared/lib/i18n';
import { Button, Modal } from 'antd';
import { WalletBg } from '../../../../lib/getImg';
import cx from './RenewalOptionsModal.module.css';

interface IRenewalOptionsModalProps {
	isRenewalOptionsModalOpen: boolean;
	closeRenewalOptionsModal: () => void;
}

export const RenewalOptionsModal = memo(
	({ isRenewalOptionsModalOpen, closeRenewalOptionsModal }: IRenewalOptionsModalProps) => {
		const t = useTranslate();
		const bgImage = {
			backgroundImage: `url(${WalletBg})`
		};

		const onCancelHandler = () => {
			closeRenewalOptionsModal();
		};
		return (
			<Modal open={isRenewalOptionsModalOpen} centered onCancel={onCancelHandler} footer={null} className={cx.root}>
				<div className={cx.content} style={bgImage}>
					<div className='mb-4 '>
						<h3 className='text-xl'>{t('not_enough_tokens')}</h3>
						<p className='opacity-40'>{t('continue_using_instruction')}:</p>
					</div>
					<div className='flex gap-x-4'>
						<div className='bg-white p-4 rounded-xl border-primary-active border-solid border'>
							<div className='mb-3'>
								<h4>{t('renew_subscription')}</h4>
								<p className='opacity-60 mt-2 text-sm'>{t('call_to_renew')}</p>
							</div>
							<Button type='primary' href='/subscribes'>
								{t('resume')}
							</Button>
						</div>
						<div className='flex flex-col justify-between p-4 rounded-xl border-gray-border border-solid border'>
							<div>
								<h4>{t('use_gift_tokens')}</h4>
								<p className='opacity-60 mt-2 text-sm'>{t('get_free_tokens_info')}</p>
							</div>
							<p className='text-xs opacity-60'>{`${t('next_charging')}: 00:00 ${t('moscow_time')}`}</p>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
);
