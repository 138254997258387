import React, { memo, ReactNode } from 'react';
import { InfoPopover } from '../InfoPopover';

interface ILabelWithInfo {
	label: ReactNode;
	tooltipText: string;
}

const LabelWithInfoComponent = ({ label, tooltipText }: ILabelWithInfo) => {
	return (
		<div>
			{label}
			<span className='px-1'>
				<InfoPopover
					content={
						<div className='max-w-[200px] w-[200px] bg-white rounded-lg relative box-border max-w-full p-1'>
							{tooltipText}
						</div>
					}
				/>
			</span>
		</div>
	);
};

export const LabelWithInfo = memo(LabelWithInfoComponent);
