import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { userApi } from '../api/userApi';
import { TUser } from './types';
import { formatBalance } from 'shared/lib/formatBalance';
import { BALANCE_MULTIPLIER } from 'shared';

export type TUserState = {
	user: TUser | null;
};

const initialState: TUserState = {
	user: null
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			isAnyOf(
				userApi.endpoints.getUser.matchFulfilled,
				userApi.endpoints.loginUser.matchFulfilled,
				userApi.endpoints.signupUser.matchFulfilled,
				userApi.endpoints.signupDemoUser.matchFulfilled,
				userApi.endpoints.updateDefaultThreadModel.matchFulfilled,
				userApi.endpoints.setOnboarded.matchFulfilled
			),
			(state, action) => {
				if (!action.payload.user) return;
				let totalBalance = Number(action.payload.user.balance) + action.payload.user.purchasedTokens;
				if (typeof action.payload.user.freeTokens === 'number') {
					totalBalance += action.payload.user.freeTokens;
				}
				state.user = { ...action.payload.user, balance: formatBalance(totalBalance * BALANCE_MULTIPLIER) };
				try {
					if (action.payload.user.email) {
						(window as any).carrotquest.identify([
							{ op: 'update_or_create', key: '$email', value: action.payload.user.email }
						]);
					}
				} catch (e) {
					console.error(e);
				}
			}
		);
		builder.addMatcher(userApi.endpoints.logoutUser.matchFulfilled, (state, action) => {
			state.user = null;
		});
	}
});
