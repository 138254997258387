import React, { FC, useEffect, useState } from 'react';
import { isLoggedIn, useLazyGetUserQuery } from 'entities/user';
import { useAppSelector } from 'shared/model';

type TAppLoaderProps = {
	children?: React.ReactNode;
};
export const AppLoader: FC<TAppLoaderProps> = ({ children }) => {
	const isUserLoggedIn = useAppSelector(isLoggedIn);
	const [isLoading, setIsLoading] = useState(true);
	const [getUser] = useLazyGetUserQuery();

	useEffect(() => {
		setIsLoading(true);
		Promise.all([getUser()]).finally(() => {
			setIsLoading(false);
		});
	}, [isUserLoggedIn]);

	return <>{isLoading ? null : children}</>;
};
