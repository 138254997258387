import React, { memo } from 'react';
import { getLocale, useTranslate } from 'shared/lib/i18n';
import { AuthBannerBg } from '../../../../lib/getImg';
import { useNavigate } from 'react-router-dom';
import { useVkPixel } from 'shared/lib';

export const UnauthorisedBanner = memo(() => {
	const t = useTranslate();
	const locale = getLocale();
	const navigate = useNavigate();
	const { trackVkEvent } = useVkPixel();
	const bgImage = {
		backgroundImage: `url(${AuthBannerBg})`
	};

	return (
		<div className='min-h-[38px] relative bg-gray-bg'>
			<div
				className='bg-gray-bg min-h-[38px] py-5 pl-9 pr-28 mr-4 bg-no-repeat bg-[right_bottom_0px] bg-[length:112px]'
				style={bgImage}
			>
				<div>
					<h3 className='text-base font-medium'>{t('unauthorized_message')}</h3>
					{locale === 'en' ? (
						<>
							<span
								className='text-sm text-primary-font hover:underline underline-offset-2 pr-1 cursor-pointer'
								onClick={() => navigate('/login')}
							>
								Login
							</span>
							<span className='opacity-40 text-sm'>or</span>
							<span
								className='text-sm text-primary-font hover:underline underline-offset-2 px-1 cursor-pointer lowercase'
								onClick={() => {
									trackVkEvent('reachGoal', 'open_registration');
									navigate('/signup');
								}}
							>
								Register
							</span>
							<span className='text-sm opacity-40'>to use the service</span>
						</>
					) : (
						<>
							<span
								className='text-sm text-primary-font hover:underline underline-offset-2 cursor-pointer'
								onClick={() => navigate('/login')}
							>
								Войдите
							</span>
							<span className='opacity-40 text-sm px-1'>или</span>
							<span
								className='text-sm text-primary-font hover:underline underline-offset-2 cursor-pointer lowercase'
								onClick={() => {
									trackVkEvent('reachGoal', 'open_registration');
									navigate('/signup');
								}}
							>
								Зарегистрируйтесь
							</span>
							<span className='text-sm opacity-40'>, чтобы пользоваться сервисом</span>
						</>
					)}
				</div>
			</div>
		</div>
	);
});
