import { EAppFormFields, TUserInputFieldsTypes } from '../model/types';

export const createEmptyUserInput = (fieldType: TUserInputFieldsTypes): Record<string, any> => {
	const emptyItem: Record<string, any> = { [EAppFormFields.BLOCK_TYPE]: fieldType };
	switch (fieldType) {
		case 'title':
			emptyItem[EAppFormFields.TITLE] = '';
			break;
		case 'text':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			emptyItem[EAppFormFields.DEFAULT_VALUE] = '';
			break;
		case 'textarea':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			emptyItem[EAppFormFields.DEFAULT_VALUE] = '';
			break;
		case 'checkbox':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			break;
		case 'select':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			emptyItem[EAppFormFields.OPTIONS] = [];
			break;
		case 'attachedFiles':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			emptyItem[EAppFormFields.IS_MULTIPLE] = '';
			emptyItem[EAppFormFields.MAX_SIZE] = '';
			emptyItem[EAppFormFields.ALLOWED_TYPES] = [];
			break;
	}
	return emptyItem;
};
