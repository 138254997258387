type TOptions = {
	expires?: Date | number | string;
	path?: string;
	domain?: string;
	secure?: boolean;
	'max-age'?: number;
};

export const setCookie = (name: string, value: string | number, options: TOptions = {}) => {
	options = {
		path: '/',
		...options
	};

	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}

	let updatedCookie = `${name}=${value}`;

	for (const optionKey in options) {
		updatedCookie += `; ${optionKey}`;
		const optionValue = options[optionKey as keyof TOptions];
		if (optionValue !== true) {
			updatedCookie += `=${optionValue}`;
		}
	}

	document.cookie = updatedCookie;
};
