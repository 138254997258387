export const getBaseApiUrl = (): string => {
	const { REACT_APP_API_URL } = window.__env__ || process.env;
	return REACT_APP_API_URL || window.location.origin;
};

export const getBackendUrl = (): string => {
	const { REACT_APP_BACKEND_URL } = window.__env__ || process.env;
	return REACT_APP_BACKEND_URL || window.location.origin;
};

export const getSubscribeId = (): string => {
	const { REACT_APP_SUBSCRIBE_ID } = window.__env__ || process.env;
	return REACT_APP_SUBSCRIBE_ID || window.location.origin;
};

export const getSentryDsn = (): string => {
	const { REACT_APP_SENTRY_DSN } = window.__env__ || process.env;
	return REACT_APP_SENTRY_DSN || '';
};

export const getSentryTunnel = (): string => {
	const { REACT_APP_SENTRY_TUNNEL } = window.__env__ || process.env;
	return REACT_APP_SENTRY_TUNNEL || '';
};

export const getPlantumlUrl = (): string => {
	const { REACT_APP_PLANTUML_URL } = window.__env__ || process.env;
	return REACT_APP_PLANTUML_URL || '';
};

export const getChartUrl = (): string => {
	const { REACT_APP_CHART_URL } = window.__env__ || process.env;
	return REACT_APP_CHART_URL || '';
};

export const getTelegramBotUrl = (): string => {
	const { REACT_APP_TELEGRAM_BOT_URL } = window.__env__ || process.env;
	return REACT_APP_TELEGRAM_BOT_URL || window.location.origin;
};

export const isDevelopMode = process.env.REACT_APP_NODE_ENV === 'development';

export const isDevelopUrl = window.location.origin.includes('.ladcloud');

export const getEnvironment = () => {
	if (!isDevelopMode && !isDevelopUrl) {
		return 'production';
	}
	return 'development';
};
