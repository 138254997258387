export const validateJSON = (_: any, value: string): Promise<void> => {
	if (!value) {
		return Promise.resolve();
	}

	try {
		JSON.parse(value);
		return Promise.resolve();
	} catch (e) {
		return Promise.reject();
	}
};
