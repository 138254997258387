import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { TVault, TVaultsFilterParams } from './types';
import { vaultsApi } from '../api/vaultsApi';
import { TVaultTagsDTO } from '../api/type';

type TVaultsState = {
	vaults: TVault[];
	filter: TVaultsFilterParams;
	page: number;
	tags: { label: string; value?: string }[];
	editableVault: TVault | null;
};

const initialState: TVaultsState = {
	vaults: [],
	filter: {},
	page: 1,
	tags: [],
	editableVault: null
};

export const vaultsSlice = createSlice({
	name: 'vaults',
	initialState,
	reducers: {
		setVaultsList: (state, action: PayloadAction<TVault[]>) => {
			state.vaults = action.payload;
		},
		setVaultsPage: (state, action: PayloadAction<number>) => {
			state.page = action.payload;
		},
		setEditableVault: (state, action: PayloadAction<TVault | null>) => {
			state.editableVault = action.payload;
		},
		setVaultsFilter: (state, action: PayloadAction<TVaultsFilterParams>) => {
			state.filter = { ...action.payload };
			state.page = 1;
		},
		updateVaultsList: (state, action: PayloadAction<TVault[]>) => {
			state.vaults = [...state.vaults, ...action.payload];
		},
		deleteVault: (state, action: PayloadAction<{ id: string }>) => {
			const vaults = state.vaults.filter((vault) => vault.id !== action.payload.id);
			state.vaults = vaults;
		},
		updateVaultIsFavourite: (state, action: PayloadAction<{ id: string; isFavourite: boolean }>) => {
			const vault = state.vaults.find((vault) => vault.id === action.payload.id);
			if (vault) {
				vault.isFavorite = action.payload.isFavourite;
			}
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			vaultsApi.endpoints.getVaultTags.matchFulfilled,
			(state, action: PayloadAction<TVaultTagsDTO>) => {
				state.tags = action.payload.docs.map((tag) => ({ label: tag.name, value: tag.id }));
			}
		);
	}
});

export const {
	setVaultsList,
	updateVaultsList,
	updateVaultIsFavourite,
	setVaultsPage,
	setVaultsFilter,
	deleteVault,
	setEditableVault
} = vaultsSlice.actions;
