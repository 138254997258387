import { Element, Root } from 'hast';
import { Plugin } from 'unified';
import { visitParents } from 'unist-util-visit-parents';

interface remarkChartOptions {
	baseUrl: string;
}

const remarkChart: Plugin<[remarkChartOptions], Root> = ({ baseUrl }) => {
	return (ast, file) => {
		visitParents(ast, 'element', (node, ancestors) => {
			if (
				node.type === 'element' &&
				node.tagName === 'code' &&
				node.children[0]?.type === 'text' &&
				Array.isArray(node.properties.className) &&
				node.properties.className.includes('language-chart')
			) {
				const base64String = window.btoa(
					new TextEncoder().encode(node.children[0]?.value).reduce((acc, byte) => acc + String.fromCharCode(byte), '')
				);
				const parent = ancestors.at(-1)!;
				const urlSafaBase64Str = encodeURIComponent(base64String);
				const img: Element = {
					type: 'element',
					tagName: 'img',
					properties: {
						src: `${baseUrl}/?data=${urlSafaBase64Str}`,
						alt: 'Chart'
					},
					children: []
				};
				const nodeIndex = parent.children.indexOf(node);
				parent.children[nodeIndex] = img;
			}
		});
	};
};

export default remarkChart;
