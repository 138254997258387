import { createSlice, isAnyOf, type PayloadAction } from '@reduxjs/toolkit';
import { TProjectDTO, TSelectedImage } from '../api/types';
import { updateArtefacts, regenerateArtefact } from './actions';
import { normalizeAppData } from '../lib/normalizeAppData';
import { Step } from 'react-joyride';
import { STYLES } from 'shared';
import { choiceControls, dynamicControls, progressTooltip, finishTooltip } from './configDemoSteps';
import { projectApi } from '../api/projectApi';

export type TProjectState = {
	project: TProjectDTO | null;
	currentArtefact: number;
	reviewedArtefact: string;
	regeneratedArtefactId: string;
	isArtefactReviewModalOpen: boolean;
	isDiagramViewerOpen: boolean;
	regenerateModalOpen: boolean;
	selectedImage: TSelectedImage | null;
	demoSteps: Step[];
	demoStepIndex: number;
};

const initialState: TProjectState = {
	project: null,
	currentArtefact: 0,
	reviewedArtefact: '',
	regeneratedArtefactId: '',
	isArtefactReviewModalOpen: false,
	isDiagramViewerOpen: false,
	regenerateModalOpen: false,
	selectedImage: null,
	demoSteps: [],
	demoStepIndex: 0
};

export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setRegenerateModalOpen: (state, action: PayloadAction<boolean>) => {
			state.regenerateModalOpen = action.payload;
		},
		setRegeneratedArtefactId: (state, action: PayloadAction<string>) => {
			state.regeneratedArtefactId = action.payload;
		},
		setReviewedArtefact: (state, action: PayloadAction<string>) => {
			state.reviewedArtefact = action.payload;
		},
		setIsArtefactReviewModalOpen: (state, action: PayloadAction<boolean>) => {
			state.isArtefactReviewModalOpen = action.payload;
		},
		setIsDiagramViewerOpen: (state, action: PayloadAction<boolean>) => {
			state.isDiagramViewerOpen = action.payload;
		},
		setSelectedImage: (state, action: PayloadAction<TSelectedImage | null>) => {
			state.selectedImage = action.payload;
		},
		updateGeneratingStatus: (state, action: PayloadAction<boolean>) => {
			if (state.project) {
				state.project.generating = action.payload;
			}
		},
		resetProject: (state) => {
			return { ...initialState };
		},
		setDemoSteps: (state, action: PayloadAction<Step[]>) => {
			return {
				...state,
				demoSteps: action.payload
			};
		},
		setDemoStepIndex: (state, action: PayloadAction<number>) => {
			state.demoStepIndex = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(updateArtefacts.fulfilled, (state, { payload }) => {
			if (state.project) {
				state.project.artefacts = [...payload];
			}
		});
		builder.addMatcher(projectApi.endpoints.getProject.matchFulfilled, (state, action: PayloadAction<TProjectDTO>) => {
			const demoSteps: Step[] = [];
			action.payload.artefacts?.forEach((artefact, index) => {
				demoSteps.push({
					content: artefact.metadata.actionDemoDescription,
					disableBeacon: true,
					disableOverlayClose: true,
					hideCloseButton: false,
					showSkipButton: false,
					showProgress: false,
					hideBackButton: true,
					placement: 'bottom',
					styles: {
						options: {
							zIndex: 10000,
							arrowColor: STYLES.colors.dark
						}
					},
					target: `.demo-artefact-${++index}`,
					title: artefact.metadata.artefactName
				});
				const dynamic_controls = demoSteps.find((step) => {
					return step.target === '.dynamic-artefact-controls';
				});
				const choice_controls = demoSteps.find((step) => {
					return step.target === '.choice-artefact-controls';
				});
				if (
					(artefact.metadata.artefactType === 'dynamic_text' ||
						['user_input', 'mailing_call'].includes(artefact.metadata.artefactBlock ?? '')) &&
					!dynamic_controls
				) {
					demoSteps.push(dynamicControls);
				}
				if (artefact.metadata.artefactType === 'choice' && !choice_controls) {
					demoSteps.push(choiceControls);
				}
			});

			return {
				...state,
				project: {
					...action.payload,
					application: normalizeAppData(action.payload.application)
				},
				currentArtefact: action.payload.artefacts?.length ? action.payload.artefacts.length - 1 : 0,
				regeneratedArtefactId: '',
				demoSteps: [...demoSteps, progressTooltip, finishTooltip]
			};
		});
		builder.addMatcher(projectApi.endpoints.deleteProject.matchFulfilled, (state, action) => {
			return { ...initialState };
		});
		builder.addMatcher(
			isAnyOf(
				projectApi.endpoints.getProject.matchRejected,
				updateArtefacts.rejected,
				projectApi.endpoints.generateArtefact.matchRejected,
				regenerateArtefact.rejected
			),
			(state, action) => {
				if (state.project) {
					state.project.generating = false;
				}
			}
		);
	}
});

export const {
	updateGeneratingStatus,
	setRegenerateModalOpen,
	setRegeneratedArtefactId,
	setIsArtefactReviewModalOpen,
	setIsDiagramViewerOpen,
	setSelectedImage,
	setReviewedArtefact,
	resetProject,
	setDemoSteps,
	setDemoStepIndex
} = projectSlice.actions;
