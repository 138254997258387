import React, { memo, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeleteOutlined, SmallLogoIcon } from 'shared/ui/Icons';
import { Button, message, Popconfirm, Typography } from 'antd';
import { formatDate } from 'shared/lib/formatDate';
import { TThreadDTO } from '../../api/type';
import { useTranslate } from 'shared/lib/i18n';
import cx from './HistoryThreadCard.module.css';
import { useDeleteThreadMutation } from '../../api/threadApi';

interface IHistoryProjectCard {
	modelName?: string;
	thread: TThreadDTO;
	threadLink: string;
	date?: string;
}

export const HistoryThreadCardComponent: FC<IHistoryProjectCard> = ({
	modelName,
	thread,
	threadLink
}: IHistoryProjectCard) => {
	const t = useTranslate();
	const navigate = useNavigate();
	const [expanded, setExpanded] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [deleteThread] = useDeleteThreadMutation();

	const handleProjectDelete = async () => {
		if (!thread.id) return;
		try {
			await deleteThread(thread.id);
			message.success({
				content: (
					<div className='flex gap-x-2'>
						<DeleteOutlined />
						<span>{t('thread_deleted')}</span>
					</div>
				),
				duration: 5,
				icon: <span />
			});
		} catch (e) {
			const error = e as Error;
			message.error({
				content: <span>{error.message}</span>,
				duration: 5
			});
		} finally {
			setOpenConfirm(false);
		}
	};

	return (
		<div
			className='cursor-pointer'
			onClick={() => {
				if (threadLink) {
					navigate(threadLink);
				}
			}}
		>
			<div className='flex items-start gap-x-8 bg-gray-bg hover:bg-gray-bg-hover rounded-2xl border-solid border border-gray-border p-5 '>
				<div className='w-full flex flex-col mb-2 gap-x-1.5'>
					<div className='flex items-center mb-2'>
						{modelName && (
							<div className='flex items-center gap-x-1.5 overflow-x-auto'>
								<SmallLogoIcon className='text-primary-active text-xl' />
								<Typography.Paragraph
									rootClassName='text-gray-secondry !m-0 !line-clamp-1'
									ellipsis={{
										rows: 2,
										expanded: false
									}}
								>
									{modelName}
								</Typography.Paragraph>
							</div>
						)}
						<div className='flex items-center ml-auto '>
							<p className='hidden lg:inline text-gray-secondry text-base m-0 whitespace-nowrap mr-3'>
								{formatDate(thread.createdAt)}
							</p>
							<Popconfirm
								title={<span>{t('thread_delete_confirm_question')}</span>}
								onConfirm={(e) => {
									e?.stopPropagation();
									handleProjectDelete();
								}}
								cancelButtonProps={{ type: 'text' }}
								okText={t('delete')}
								cancelText={t('cancel')}
								destroyTooltipOnHide={true}
								open={openConfirm}
								onCancel={(e) => {
									e?.stopPropagation();
									setOpenConfirm(false);
								}}
							>
								<Button
									className='focus:!outline-none'
									type='text'
									shape='circle'
									icon={<DeleteOutlined />}
									onClick={(e) => {
										e.stopPropagation();
										setOpenConfirm(true);
									}}
								/>
							</Popconfirm>
						</div>
					</div>
					<Typography.Paragraph
						rootClassName={cx.thread}
						ellipsis={{
							rows: 4,
							expandable: 'collapsible',
							expanded,
							symbol: (expanded) =>
								expanded ? (
									<div
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setExpanded(false);
										}}
									>
										{t('roll_up')}
									</div>
								) : (
									<div
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setExpanded(true);
										}}
									>
										{t('expand')}
									</div>
								)
						}}
					>
						{thread.title}
					</Typography.Paragraph>
				</div>
			</div>
		</div>
	);
};

export const HistoryThreadCard = memo(HistoryThreadCardComponent);
