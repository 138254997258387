import axios from 'axios';

import { getLocale } from '../lib/i18n';
import { getBaseApiUrl } from '../env';

const baseURL = getBaseApiUrl();

const axiosInstance = axios.create({
	baseURL: baseURL,
	withCredentials: true
});

axiosInstance.interceptors.request.use(
	(config) => {
		const locale = getLocale();

		config.params = {
			...config.params,
			locale
		};

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
export { axiosInstance };
