import React, { type ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ErrorFallback } from '../../widgets/ErrorFallback';

interface ILayout {
	sideNavSlot?: ReactNode;
	mobileNavSlot?: ReactNode;
}

export const Layout: React.FC<ILayout> = ({ sideNavSlot, mobileNavSlot }) => {
	const location = useLocation();
	return (
		<div className='h-100dvh'>
			<div className='max-h-100dvh h-100dvh mx-auto flex flex-col relative'>
				{mobileNavSlot}
				<div className='flex grow overflow-y-hidden md:pt-0 pt-[64px]'>
					{sideNavSlot}
					<Sentry.ErrorBoundary key={location.pathname} fallback={<ErrorFallback />}>
						<main className='w-full flex flex-1 overflow-hidden'>
							<Outlet />
						</main>
					</Sentry.ErrorBoundary>
				</div>
			</div>
		</div>
	);
};
