import React, { memo, useMemo } from 'react';
import { InfoCircleFilled } from 'shared/ui/Icons';
import { Button, Divider, PopoverProps } from 'antd';
import { CustomPopover } from 'shared/ui';
import { STYLES } from 'shared';
import { useTranslate } from 'shared/lib/i18n';

const TokensToPagePopoverComponent = ({
	className,
	balance,
	align,
	prices
}: {
	className: string;
	balance: number;
	align?: PopoverProps['align'];
	prices: { model: string; gptzToPage: number }[];
}) => {
	const t = useTranslate();
	const content = useMemo(() => {
		return (
			<div className='text-white min-w-[290px] max-w-[290px] p-3'>
				<div className='text-xs flex justify-between text-white opacity-[0.65] mb-2 lowercase'>
					<span>{t('model')}</span>
					<div>
						<span>{t('page_count')}</span>
						<span className='text-white opacity-[0.65] ml-1'>&#42;</span>
					</div>
				</div>
				<div className='flex flex-col justify-between gap-2'>
					{prices.map((model) => {
						return (
							<div className='flex justify-between' key={model.model}>
								<span className='max-w-[70%] overflow-hidden text-ellipsis'>{model.model}</span>
								<span>≈{Math.round(balance * model.gptzToPage)}</span>
							</div>
						);
					})}
				</div>
				<Divider className='bg-white opacity-[0.65] my-5' />
				<div className='flex'>
					<div className='text-white opacity-[0.65] mr-1'>&#42;</div>
					<div className='text-white opacity-[0.65]'>{t('about_calculations')}</div>
				</div>
			</div>
		);
	}, [prices]);

	return (
		<CustomPopover
			content={content}
			color={STYLES.colors.dark_secondary}
			placement='right'
			align={align}
			className={className}
			destroyTooltipOnHide={true}
		>
			<Button type='text' shape='circle' size='small' icon={<InfoCircleFilled className='text-base' />}></Button>
		</CustomPopover>
	);
};

export const TokensToPagePopover = memo(TokensToPagePopoverComponent);
