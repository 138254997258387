// TODO check imports from lib/i18n index
// import { I18NProvider } from '@ayub-begimkulov/i18n';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import React, { Suspense, useEffect } from 'react';

import './index.css';
import 'reactflow/dist/style.css';
import { theme } from '../shared/config/theme/themeConfig';

import { useI18n, useTranslate } from '../shared/lib/i18n';
import { antdRuLocale, antdEnLocale, validateRuMessages, validateEnMessages } from '../shared/config';
import { getSentryDsn, getSentryTunnel, getEnvironment, isDevelopMode } from '../shared/env';
import { AppRouter } from './router/AppRouter';
import { AppLoader } from './loaders/AppLoader';

Sentry.init({
	dsn: getSentryDsn(),
	integrations: [new Sentry.BrowserTracing()],
	tracesSampleRate: 1.0,
	tunnel: getSentryTunnel(),
	environment: getEnvironment(),
	enabled: !isDevelopMode
});

function App() {
	const { lang } = useI18n();
	const t = useTranslate();

	useEffect(() => {
		const metaDescription = document.querySelector('meta[name="description"]');
		if (metaDescription) {
			metaDescription.setAttribute('content', t('app_description'));
		}
	}, [lang]);

	return (
		<Suspense>
			<ConfigProvider
				locale={lang === 'en' ? antdEnLocale : antdRuLocale}
				form={{
					validateMessages: lang === 'en' ? validateEnMessages : validateRuMessages
				}}
				theme={theme}
			>
				<AppLoader>
					<AppRouter />
				</AppLoader>
			</ConfigProvider>
		</Suspense>
	);
}

export default App;
