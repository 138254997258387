import { TProjectState } from './slice';

export const selectCurrentProject = (state: RootState): TProjectState => {
	return state.project;
};

export const selectProjectHeader = (state: RootState) => {
	return { title: state.project.project?.application.name || '', description: state.project.project?.name || '' };
};

export const selectAppSteps = (state: RootState) => {
	const { project } = state.project;
	const actions = project?.actions || project?.application.actions || [];
	return actions;
};
