type TDebounceProps<T extends (...args: any[]) => any> = (func: T, delay: number) => (...args: Parameters<T>) => void;

export const methodDebounce: TDebounceProps<(...args: any[]) => void> = (func, delay) => {
	let timeoutId: ReturnType<typeof setTimeout>;
	return function (...args) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
};
