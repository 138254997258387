import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { rootReducer } from './rootReducer';
import { baseApi } from 'shared/api/baseApi';

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
	devTools: process.env.NODE_ENV !== 'production'
});

setupListeners(store.dispatch);
