import { useEffect, useState } from 'react';

export const useMobile = (): boolean => {
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const handleResize = () => {
		setIsMobile(() => window.innerWidth < 768);
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', handleResize);
			handleResize();
		}

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return isMobile;
};
