import React, { memo } from 'react';
import { Position, NodeProps } from 'reactflow';
import cn from 'classnames';
import LimitedHandle from '../CustomHandle';
import { VaultActionIcon } from 'shared/ui/Icons';
import { Divider } from 'antd';
import { useTranslate } from 'shared/lib/i18n';
import { NodeActions } from '../NodeActions';

const VaultCallActionComponent = (props: NodeProps) => {
	const t = useTranslate();
	const { data, selected } = props;
	return (
		<div
			className={cn(
				'p-3 rounded-md bg-white border border-solid border-gray-border min-w-[276px] max-w-[276px] min-h-[14px] max-h-[90px] relative',
				{
					'border-primary-active border-2': selected,
					'!border-error border-2': data.status === 'error'
				}
			)}
		>
			<div>
				<div className='flex gap-x-2 items-center'>
					<div className='bg-fill-tertiary rounded min-w-[20px] min-h-[20px] flex items-center justify-center'>
						<VaultActionIcon />
					</div>
					<div className='text-sm font-medium'>{t('vault_call')}</div>
					<div className='ml-auto'>{!!data.slug && <NodeActions currentSlug={data.slug} />}</div>
				</div>
				<Divider style={{ margin: '8px 0' }} />
				<div className='flex items-center justify-between gap-x-2.5'>
					<div className='text-sm overflow-hidden text-ellipsis whitespace-nowrap'>{data.blockName}</div>
					<div className='text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'>
						{data.slug}
					</div>
				</div>
			</div>

			<LimitedHandle type='target' position={Position.Top} className='opacity-0' limit={1} />
			<LimitedHandle
				type='source'
				position={Position.Bottom}
				className='!rounded-full !border-solid !border-primary !border-2 !w-2 !h-2 !bg-white !-bottom-[5px]'
				limit={2}
			/>
		</div>
	);
};

export const VaultCallAction = memo(VaultCallActionComponent);
