export enum ERoutes {
	HOME = '/',
	APPS = 'apps',
	FAVOURITE_APPS = 'apps/favourite',
	MY_APPS = 'apps/my',
	PROJECTS_HISTORY = 'history',
	THREADS_HISTORY = 'history/threads',
	CREATE_THREAD = 'thread',
	VAULTS = 'vaults',
	FAVOURITE_VAULTS = 'vaults/favourite',
	THREAD = 'thread/:threadId',
	APP = 'apps/:id',
	APP_EDIT = 'apps/:id/edit',
	PROJECT = 'apps/:id/:projectId',
	SUBSCRIBES = 'subscribes',
	RESET_PASSWORD = 'reset-password/:token',
	FORGOT_PASSWORD = 'forgot-password',
	LOGIN = 'login',
	SIGNUP = 'signup',
	EMPLOYEES = 'employees'
}
