import { baseApi } from 'shared/api/baseApi';
import { API_TEMPLATES_TAG } from 'shared/api/tags';
import { TApiTemplatesDTO } from './type';
import qs from 'qs';
import { TApiTemplate } from '../model/types';
import { TEMPLATES_PER_PAGE } from '../model/constants';

export const apiTemplatesApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getApiTemplates: build.query<TApiTemplatesDTO, { search?: string; page: number; vaultsPerPage?: number }>({
			query: ({ search, page, vaultsPerPage }) => {
				const query: any = {
					name: {
						contains: search
					}
				};

				const stringifiedQuery = qs.stringify(
					{
						where: query,
						limit: vaultsPerPage ?? TEMPLATES_PER_PAGE,
						page
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `apps_steps_api_templates${stringifiedQuery}`
				};
			},
			providesTags: [API_TEMPLATES_TAG]
		}),
		getApiTemplate: build.query<TApiTemplate, string>({
			query: (id) => ({
				url: `apps_steps_api_templates/${id}`
			})
		})
	})
});

export const { useGetApiTemplatesQuery, useGetApiTemplateQuery, useLazyGetApiTemplateQuery } = apiTemplatesApi;
