import { GptCallFields as GptCall } from './GptCallFields';
import { IdeaFields as Idea } from './IdeaFields';
import { ApiCallFields as ApiCall } from './ApiCallFields';
import { UserInputCallFields as UserInputCall } from './UserInputCallFields';
import { ApiTemplateCallFields as ApiTemplateCall } from './ApiTemplateCallFields';
import { JsonataCallFields as JsonataCall } from './JsonataCallFields';
import { LlmCallFields as LlmCall } from './LlmCallFields';
import { MailingCallFields as MailingCall } from './MailingCallFields';
import { TaskCallFields as TaskCall } from './TaskCallFields';
import { VaultCallFields as VaultCall } from './VaultCallFields';

export const AppFormFields = {
	GptCall,
	Idea,
	ApiCall,
	ApiTemplateCall,
	JsonataCall,
	LlmCall,
	MailingCall,
	TaskCall,
	VaultCall,
	UserInputCall
};
