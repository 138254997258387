import React, { JSX, lazy } from 'react';
import { redirect, RouteObject } from 'react-router-dom';
import { ERoutes } from 'shared/constants/routes';

const SubscribesPage = lazy(() =>
	import('pages').then(({ SubscribesPage }) => ({
		default: SubscribesPage
	}))
);

const AuthLayout = lazy(() =>
	import('../layouts/AuthLayout').then(({ AuthLayout }) => ({
		default: AuthLayout
	}))
);

const BaseLayout = lazy(() =>
	import('../layouts/BaseLayout').then(({ BaseLayout }) => ({
		default: BaseLayout
	}))
);

const HomePage = lazy(() =>
	import('pages/HomePage').then(({ HomePage }) => ({
		default: HomePage
	}))
);

const HelperPage = lazy(() =>
	import('pages/HelperPage').then(({ HelperPage }) => ({
		default: HelperPage
	}))
);
const EditAppPage = lazy(() =>
	import('pages/EditAppPage').then(({ EditAppPage }) => ({
		default: EditAppPage
	}))
);

const AppsPage = lazy(() =>
	import('pages/AppsPage').then(({ AppsPage }) => ({
		default: AppsPage
	}))
);

const VaultsPage = lazy(() =>
	import('pages/VaultsPage').then(({ VaultsPage }) => ({
		default: VaultsPage
	}))
);

const FavouriteVaultsPage = lazy(() =>
	import('pages/FavouriteVaultsPage').then(({ FavouriteVaultsPage }) => ({
		default: FavouriteVaultsPage
	}))
);

const FavouriteAppsPage = lazy(() =>
	import('pages/FavouriteAppsPage').then(({ FavouriteAppsPage }) => ({
		default: FavouriteAppsPage
	}))
);

const MyAppsPage = lazy(() =>
	import('pages/MyAppsPage').then(({ MyAppsPage }) => ({
		default: MyAppsPage
	}))
);

const ThreadsHistoryPage = lazy(() =>
	import('pages/ThreadsHistoryPage').then(({ ThreadsHistoryPage }) => ({
		default: ThreadsHistoryPage
	}))
);

const ProjectsHistoryPage = lazy(() =>
	import('pages/ProjectsHistoryPage').then(({ ProjectsHistoryPage }) => ({
		default: ProjectsHistoryPage
	}))
);

const EmployeesPage = lazy(() =>
	import('pages/EmployeesPage').then(({ EmployeesPage }) => ({
		default: EmployeesPage
	}))
);

const LoginPage = lazy(() =>
	import('pages/LoginPage').then(({ LoginPage }) => ({
		default: LoginPage
	}))
);

const ProjectPage = lazy(() =>
	import('pages/ProjectPage').then(({ ProjectPage }) => ({
		default: ProjectPage
	}))
);

const SignupPage = lazy(() =>
	import('pages/SignupPage').then(({ SignupPage }) => ({
		default: SignupPage
	}))
);

const ForgotPasswordPage = lazy(() =>
	import('pages/ForgotPasswordPage').then(({ ForgotPasswordPage }) => ({
		default: ForgotPasswordPage
	}))
);

const ResetPasswordPage = lazy(() =>
	import('pages/ResetPasswordPage').then(({ ResetPasswordPage }) => ({
		default: ResetPasswordPage
	}))
);

const NotFoundPage = lazy(() =>
	import('pages/NotFound').then(({ NotFoundPage }) => ({
		default: NotFoundPage
	}))
);

const CreateThreadPage = lazy(() =>
	import('pages/CreateThreadPage').then(({ CreateThreadPage }) => ({
		default: CreateThreadPage
	}))
);

const ThreadPage = lazy(() =>
	import('pages/ThreadPage').then(({ ThreadPage }) => ({
		default: ThreadPage
	}))
);

export type TRouteItem = Omit<RouteObject, 'children' | 'element'> & {
	id: string;
	element?:
		| React.LazyExoticComponent<() => JSX.Element>
		| React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
	private?: boolean;
	children?: TRouteItem[];
};

export const RouterConfig: TRouteItem[] = [
	{
		path: ERoutes.HOME,
		private: false,
		id: 'base-layout',
		element: BaseLayout,
		children: [
			{
				index: true,
				id: 'home',
				element: HomePage,
				loader: () => redirect('apps')
			},
			{
				path: ERoutes.APPS,
				id: 'apps',
				element: AppsPage
			},
			{
				private: true,
				path: ERoutes.FAVOURITE_APPS,
				id: 'apps-favourite',
				element: FavouriteAppsPage
			},
			{
				private: true,
				path: ERoutes.MY_APPS,
				id: 'apps-my',
				element: MyAppsPage
			},
			{
				private: true,
				path: ERoutes.PROJECTS_HISTORY,
				id: 'project-history',
				element: ProjectsHistoryPage
			},
			{
				private: true,
				path: ERoutes.THREADS_HISTORY,
				id: 'threads-history',
				element: ThreadsHistoryPage
			},
			{
				path: ERoutes.CREATE_THREAD,
				id: 'thread-create',
				element: CreateThreadPage
			},
			{
				path: ERoutes.THREAD,
				id: 'thread',
				element: ThreadPage
			},
			{
				path: ERoutes.VAULTS,
				id: 'vaults',
				element: VaultsPage
			},
			{
				path: ERoutes.FAVOURITE_VAULTS,
				id: 'vaults-favourite',
				element: FavouriteVaultsPage
			},
			{
				path: ERoutes.EMPLOYEES,
				id: 'employees',
				element: EmployeesPage
			},
			{
				path: ERoutes.APP,
				id: 'app',
				element: HelperPage
			},
			{
				private: true,
				path: ERoutes.PROJECT,
				id: 'project',
				element: ProjectPage
			},
			{
				private: true,
				path: ERoutes.SUBSCRIBES,
				id: 'subscribes',
				element: SubscribesPage
			}
		]
	},
	{
		private: true,
		path: ERoutes.APP_EDIT,
		id: 'edit-app',
		element: EditAppPage
	},
	{
		id: 'auth-layout',
		element: AuthLayout,
		children: [
			{
				path: ERoutes.RESET_PASSWORD,
				id: 'reset-password',
				element: ResetPasswordPage
			},
			{
				path: ERoutes.FORGOT_PASSWORD,
				id: 'forgot-password',
				element: ForgotPasswordPage
			},
			{
				path: ERoutes.LOGIN,
				id: 'login',
				element: LoginPage
			},
			{
				path: ERoutes.SIGNUP,
				id: 'signup',
				element: SignupPage
			}
		]
	},
	{
		element: BaseLayout,
		id: 'base-public',
		children: [{ path: '*', id: 'not-found', element: NotFoundPage }]
	}
];
