import { useParams } from 'react-router-dom';

export const useStrictParams = <T extends Record<string, string>>() => {
	const params = useParams<T>();
	for (const key in params) {
		if (!params[key]) {
			throw new Error(`Missing parameter: ${key}`);
		}
	}
	return params as T;
};
