export const MODELS_PRICING: { model: string; gptzToPage: number }[] = [
	{ model: 'chat-GPT 4o', gptzToPage: 0.7619047619 },
	{ model: 'Chat GPT-4o mini', gptzToPage: 19.53601954 },
	{ model: 'wizard', gptzToPage: 14.65201465 },
	{ model: 'GigaChat - pro', gptzToPage: 0.6349206349 },
	{ model: 'Claude 3.5 Sonnet', gptzToPage: 0.7936507937 }
];

export const BALANCE_MULTIPLIER = 100;
export const LOWER_BALANCE_LIMIT = 100;
