import { Element, Root } from 'hast';
import { Plugin } from 'unified';
import { visitParents } from 'unist-util-visit-parents';
import plantumlEncoder from 'plantuml-encoder';

const DEFAULT_PLANTUML_URL = 'https://www.plantuml.com/plantuml/png';

interface remarkPlantUmlOptions {
	baseUrl?: string;
}

const remarkPlantUml: Plugin<[remarkPlantUmlOptions?], Root> = (options) => {
	const baseUrl = options?.baseUrl ? options.baseUrl : DEFAULT_PLANTUML_URL;
	return (ast, file) => {
		visitParents(ast, 'element', (node, ancestors) => {
			if (
				node.type === 'element' &&
				node.tagName === 'code' &&
				node.children[0]?.type === 'text' &&
				node.children[0].value.includes('@startuml')
			) {
				const parent = ancestors.at(-1)!;
				const img: Element = {
					type: 'element',
					tagName: 'img',
					properties: {
						src: `${baseUrl.replace(/\/$/, '')}/${plantumlEncoder.encode(node.children[0].value)}`,
						alt: 'PlantUML Diagram'
					},
					children: []
				};
				const nodeIndex = parent.children.indexOf(node);
				parent.children[nodeIndex] = img;
			}
		});
	};
};

export default remarkPlantUml;
