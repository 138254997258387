import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../shared/api/axiosInstance';
import { AREA_NAME } from '../model/constants';
import { TProjectDTO, TArtefact } from '../api/types';

export const getProject = createAsyncThunk<TProjectDTO, { projectId: string; signal?: AbortSignal }>(
	`${AREA_NAME}/getProjects`,
	async ({ projectId, signal }, { rejectWithValue }) => {
		try {
			const { data } = await axiosInstance.get(`projects/${projectId}`, {
				withCredentials: true,
				signal
			});
			return data;
		} catch (e) {
			const error = e as Error;
			return rejectWithValue(error.message);
		}
	}
);

export const updateArtefacts = createAsyncThunk<TArtefact<any>[], { projectId: string; artefacts: TArtefact<any>[] }>(
	`${AREA_NAME}/updateArtefacts`,
	async ({ projectId, artefacts }, { rejectWithValue }) => {
		try {
			const { data } = await axiosInstance.patch(`projects/${projectId}`, { artefacts }, { withCredentials: true });
			return data.doc.artefacts;
		} catch (e) {
			const error = e as Error;
			return rejectWithValue(error.message);
		}
	}
);

export const regenerateArtefact = createAsyncThunk<
	TArtefact<any>[],
	{ projectId: string; artefactId: string; comment: string }
>(`${AREA_NAME}/generateNextArtefact`, async ({ projectId, artefactId, comment }, { rejectWithValue }) => {
	try {
		const { data } = await axiosInstance.post(
			`projects/${projectId}/regenerate/${artefactId}`,
			{ comment },
			{ withCredentials: true }
		);
		return data;
	} catch (e) {
		const error = e as Error;
		return rejectWithValue(error.message);
	}
});
