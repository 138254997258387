import React, { memo } from 'react';
import { Divider, Form, Input, Switch } from 'antd';
import { EAppFormFields, TUserInputItemFields } from '../../../../model/types';
import { DeleteOutlined } from 'shared/ui/Icons';
import { validateNotEmpty } from 'shared/lib';
import { useTranslate } from 'shared/lib/i18n';

const TextFieldsComponent = ({ action, onChange, path, handleRemoveFields, index }: TUserInputItemFields) => {
	const t = useTranslate();

	return (
		<div>
			<Divider style={{ margin: '8px 0' }} />
			<div className='flex w-full justify-between'>
				<div>{t('input.text')}</div>
				<DeleteOutlined
					className='text-error'
					onClick={() => {
						handleRemoveFields(index);
					}}
				/>
			</div>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, index, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.BLOCK_TYPE] ?? 'text'}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={t('key_name')}
				name={[...path, index, EAppFormFields.NAME]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.NAME]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') },
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={t('label')}
				name={[...path, index, EAppFormFields.LABEL]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.LABEL]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') },
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.LABEL], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('default_value')}</span>}
				name={[...path, index, EAppFormFields.DEFAULT_VALUE]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.DEFAULT_VALUE]}
				rules={[
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') },
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.DEFAULT_VALUE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('required')}</span>}
				name={[...path, index, EAppFormFields.REQUIRED]}
				initialValue={action.data[EAppFormFields.FIELDS]?.[index]?.[EAppFormFields.REQUIRED]}
			>
				<Switch onChange={(e) => onChange([...path, `${index}`, EAppFormFields.REQUIRED], e)} />
			</Form.Item>
		</div>
	);
};

export const TextFields = memo(TextFieldsComponent);
