/* eslint-disable */
import { memo } from 'react';
import { Button, Modal } from 'antd';
import { useTranslate } from '@ayub-begimkulov/i18n';

interface IAgreementModalProps {
	isAgreementModalOpen: boolean;
	closeAgreementModalModalHandler: () => void;
}

export const AgreementModal = memo(
	({ isAgreementModalOpen, closeAgreementModalModalHandler }: IAgreementModalProps) => {
		const t = useTranslate();
		const onConfirmHandler = () => {
			closeAgreementModalModalHandler();
		};

		return (
			<Modal
				open={isAgreementModalOpen}
				width={850}
				centered
				onCancel={onConfirmHandler}
				onOk={onConfirmHandler}
				footer={null}
			>
				<div className='max-w-[850px] max-h-[600px] py-7 grow bg-white sm:p-10 p-7 w-full rounded-2xl flex flex-col items-center text-charcoal-light overflow-y-auto'>
					<div className='w-full'>
						<div className='w-full flex items-center mb-5 relative'>
							<h1 className='w-full px-24 text-3xl text-center'>
								Пользовательское соглашение онлайн-сервис «GPTZATOR»
							</h1>
						</div>
						<p className='font-bold w-full text-center mb-7'>
							Версия от "22" декабря 2023 г., Российская Федерация, г. Нижний Новгород
						</p>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>1. ПРЕАМБУЛА. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.1.</span>
								Пользовательское соглашение опубликовано ОБЩЕСТВОМ С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ЛАД-Интеллект», в
								лице Генерального директора Петрова Дмитрия Николаевича, действующего на основании Устава (далее по
								тексту ⎯ "Администрация"), с одной стороны, в целях урегулирования порядка предоставления доступа к
								веб-сайту{' '}
								<a
									className='text-primary hover:text-primary hover:underline underline-offset-2'
									href='https://gptzator.ru/'
								>
									https://gptzator.ru/
								</a>{' '}
								, физическим лицам, обладающим полной дееспособностью, в т. ч. зарегистрированных в качестве
								индивидуального предпринимателя или плательщика налога на профессиональный доход, а также юридическим
								лицам, от имени и в интересах которых действуют уполномоченные представители, обладающие достаточным
								объемом прав и полномочий (далее по тексту ⎯ "Пользователь"), с другой стороны, вместе в дальнейшем
								именуемые ⎯ "Стороны", а каждый индивидуально ⎯ "Сторона". Администрация предоставляет Пользователю
								доступ к указанным веб-сайтам на условиях, изложенных в Публичной оферте и настоящем Пользовательском
								соглашении.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.2.</span>
								Для целей применения и толкования положений Пользовательского соглашения, Администрацией используются
								установленные в Публичной оферте и Политике конфиденциальности термины (если в Пользовательском
								соглашении прямо не указано иное). В тексте Пользовательского соглашения эти термины могут быть
								использованы в другом падеже, в единственном или множественном числе, с маленькой или большой буквы,
								либо в виде сокращений.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.3.</span>
								Обязательным условием использования веб-сайта, получения доступа к закрытым разделам веб-сайта, является
								предварительное ознакомление Пользователя с Публичной офертой, Пользовательским соглашением, Политикой
								конфиденциальности, предоставление по собственной воле и в своих интересах, конкретного, предметного,
								информированного, сознательного и однозначного согласия с обработкой его персональных данных.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.4.</span>
								Подтверждением об ознакомлении и согласии с документами, указанными в п. 1.3. Пользовательского
								соглашения, является активация Пользователем специального флажка ("чекбокса"): "Я ознакомлен (-на) и
								согласен (-на) с Публичной офертой и Политикой конфиденциальности, даю свое согласие на обработку моих
								персональных данных" в контрольном пункте специальной формы на веб-сайте.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>2. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.1.</span>В порядке и на условиях предусмотренных Пользовательским
								соглашением, Администрация предоставляет Пользователю доступ к веб-сайту, который содержит необходимую и
								достоверную информацию об условиях использования онлайн-сервиса, обеспечивающая возможность его
								правильного использования, информацию о видах Тарифного плана, его стоимости (цены) в рублях, об адресе
								(местонахождении) и фирменном наименовании Администрации, правовые документы регулирующие его
								использование, и другую необходимую для Пользователя информацию.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.2.</span>
								Администрация предоставляет Пользователю доступ к веб-сайту на безвозмездной основе, за исключением
								доступа непосредственно к платным функциям онлайн-сервиса, что осуществляется Администрацией на платной
								основе в порядке и способом, предусмотренным Публичной офертой.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.3.</span>В случае возникновения у Пользователя каких-либо вопросов,
								касающихся порядка использования онлайн-сервиса, порядка обработки персональных данных, а также любых
								других вопросов касательно взаимных прав и обязанностей сторон, перед оформлением заказа и оплатой
								Тарифного плана, Пользователь обязан обратиться к Администрации для получения информационной или
								технической поддержки.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.4.</span>
								Пользователь самостоятельно знакомится с онлайн-сервисом и правовыми документами, регулирующими его
								работу, и оформляет доступ к онлайн-сервису с помощью интерактивного механизма оформления заказа.
								Пользователь выбирает интересующий его Тарифный план, после чего, производит его оплату с помощью
								платежной системы веб-сайта либо с помощью иного способа оплаты, путем нажатия на кнопку "Купить",
								"Получить доступ", "Подтвердить заказ", либо на иную аналогичную по смыслу кнопку.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.5.</span>
								После поступления заказа, Администрация может связаться с Пользователем в целях уточнения деталей
								заказа, согласования порядка оплаты Тарифного плана, а также иных деталей в целях исполнения своих
								обязанностей по Договору. Пользователь несет персональную ответственность за предоставление неверных
								сведений, повлекшее за собой невозможность надлежащего исполнения Администрацией своих обязанностей
								перед ним.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.6.</span>
								Администрация принимает и оформляет заказы по графику, определенному на веб-сайте, а в случае отсутствия
								такого графика, с понедельника по субботу, с 9-00 по 18-00 по МСК. Покупатель вправе оформить заказ при
								условии работоспособности веб-сайта и наличия интересующего его Тарифного плана в продаже.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.7.</span>
								Доступ к онлайн-сервису предоставляется Пользователю в пределах, установленных функциональными
								возможностями онлайн-сервиса и подключенным Тарифным планом, на условиях, предусмотренных в Публичной
								оферте, путем фактического предоставления доступа к закрытым зонам и функциям веб-сайта
								(онлайн-сервиса).
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>3. РЕГИСТРАЦИЯ НА ВЕБ-САЙТЕ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.1.</span>В целях получения доступа и права использования
								онлайн-сервиса Пользователю требуется прохождение добровольной процедуры регистрации учетной записи на
								веб-сайте. Пользователь должен сообщить Администрации свой адрес электронной почты и придумать пароль,
								путем ввода указанной информации в специальное поле в момент регистрации.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.2.</span>
								Администрация направляет Пользователю, по указанному адресу электронной почты, специальную ссылку для
								доступа в личный кабинет, которую он должен активировать в целях завершения процедуры регистрации.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.3.</span>
								После совершения указанных действий, а также аутентификации и авторизации, Пользователь считается
								успешно прошедшим процедуру добровольной регистрации на веб-сайте, и ему предоставляется доступ к
								личному кабинету и онлайн-сервису, в пределах выбранного и оплаченного Тарифного плана.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.4.</span>
								Пользователь может получить доступ к бесплатным зонам веб-сайта или онлайн-сервиса по решению
								Администрации в ознакомительных целях и в установленных пределах.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.5.</span>
								Последующий доступ Пользователя к учетной записи осуществляется посредством введения логина и пароля в
								контрольном пункте специальной формы на веб-сайте.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.6.</span>В случае невозможности аутентификации на веб-сайте
								Пользователь вправе обратиться к Администрации с целью восстановления доступа к учетной записи с помощью
								доступных средств связи. Окончательное решение о восстановлении доступа принимается Администраций исходя
								из причин невозможности произвести аутентификацию.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.7.</span>
								Пользователь обязан поддерживать регистрационные и иные данные о себе в актуальном состоянии.
								Администрация не несёт ответственность за точность и правильность информации, предоставляемой
								Пользователем при регистрации на веб-сайте.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.8.</span>В случае обнаружения несанкционированной активности третьих
								лиц относительно учетной записи, либо возникновения подозрения о компрометации логина и пароля,
								Пользователь обязан известить об этом Администрацию при первой возможности.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.9.</span>
								Предполагается, что любые действия, совершённые Пользователем с использованием логина и пароля,
								совершены от имени и в интересах Пользователя, пока не доказано обратное, за исключением случаев, когда
								Пользователь уведомил Администрацию о несанкционированном доступе к его учетной записи со стороны
								третьих лиц и такая информация подтвердилась.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.10.</span>
								Пользователь несет ответственность за сохранность своего логина и пароля, и не вправе передавать эти
								данные третьим лицам. Пользователь вправе хранить логин и пароль любыми удобными для него способами,
								которые, однако, обеспечат надлежащую защиту данной информации и соблюдение достаточных мер
								предосторожности.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.11.</span>
								Администрация вправе отказать Пользователю в регистрации на веб-сайте без объяснения причин либо удалить
								(заблокировать, приостановить действие) его учетной записи в случае нарушения Публичной оферты,
								Пользовательского соглашения или Политики конфиденциальности, по личному обращению Пользователя либо в
								случае возникновения у Администрации обоснованных сомнений относительно несанкционированного доступа
								третьих лиц к учетной записи.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>4. ЗАПРЕТЫ И ПРЕДУПРЕЖДЕНИЯ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.1.</span>
								Пользователю запрещается:
								<ul className='list-disc m-0 pl-8'>
									<li>
										предоставлять веб-сайту недостоверные данные о себе либо персональные данные и иную конфиденциальную
										информацию третьих лиц без их согласия, оформленного в установленном законом порядке;
									</li>
									<li>
										передавать право доступа к учетной записи и (или) онлайн-сервису в пользование третьим лицам, в т.
										ч. путем продажи, аренды, мены или бесплатного использования;
									</li>
									<li>выдавать себя за другое лицо либо предоставлять недостоверные (поддельные) документы;</li>
									<li>
										предпринимать действия программно-технического характера, которые могут рассматриваться как очевидно
										нарушающие нормальную работу и функционирование веб-сайта;
									</li>
									<li>
										использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства
										или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания
										веб-сайта;
									</li>
									<li>
										обходить навигационную структуру веб-сайта либо иным способом обеспечивать себе несанкционированный
										доступ к его функциям в целях получения информации, документов, материалов или доступа к закрытым
										разделам веб-сайта, которые правомерно не доступны для Заказчика;
									</li>
									<li>нарушать систему безопасности или аутентификации на веб-сайте;</li>
									<li>выполнять обратный поиск, отслеживать информацию о других участниках обучения;</li>
									<li>
										использовать веб-сайт и его содержание в запрещенных законодательством Российской Федерации целях
										или способом, не предусмотренным Договором, а также, подстрекать к таким действиям третьих лиц.
									</li>
								</ul>
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.2.</span>В случае нарушения Пользователем любого из вышеуказанных
								положений, Администрация оставляет за собой право ввести ограничительные меры в отношении такого
								Пользователя и приостановить (прекратить) его доступ к онлайн-сервису частично или целиком.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>5. ОТКАЗ ОТ ОТВЕТСТВЕННОСТИ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>5.1.</span>
								Администрация не несет ответственности за наступление следующих обстоятельств (событий) при
								использовании веб-сайта Пользователем и возможные негативные последствия в связи с их наступлением:
								<ul className='list-none m-0 pl-3'>
									<li>
										&mdash; невозможность использования веб-сайта по причине неполадок интернет-провайдера,
										электросетей, а также программно-технических ограничений или неполадок на стороне самого
										Пользователя, которые могут напрямую или косвенно влиять на работу веб-сайта;
									</li>
									<li>&mdash; полезность и результативность использования веб-сайта для Пользователя;</li>
									<li>
										&mdash; получение убытков независимо от их вида и причин возникновения, в том числе упущенной
										выгоды. Пользователь использует веб-сайт исключительно по собственной инициативе, руководствуясь
										своим внутренним убеждением, на свой страх и риск, если иное не установлено Публичной офертой или
										федеральными законами;
									</li>
									<li>&mdash; действия платежных систем и финансовых учреждений при обработке платежей;</li>
									<li>
										&mdash; ненадлежащее функционирование веб-сайта в связи с отсутствием у Пользователя необходимых и
										достаточных программно-технических средств для его использования.
									</li>
								</ul>
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>5.2.</span>
								Администрация не берет на себя никаких обязанностей по обеспечению Пользователя программно-техническими
								средствами, необходимыми и достаточными для надлежащего использования веб-сайта.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>5.3.</span>
								Администрация не несет ответственности за результаты использования веб-сайта Пользователем в иных
								случаях, кроме прямо предусмотренных Публичной офертой, Пользовательским соглашением или положениями
								законодательства Российской Федерации.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>6. ЗАЩИТА ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>6.1.</span>
								Пользователь соглашается с тем, что все материалы, представленные на веб-сайте, онлайн-сервис, включая
								аудиовизуальные и иные изображения, программный код, логотип, графика, отзывы, оставленные
								Пользователем, иной представленный контент, является объектами интеллектуальной собственности
								Администрации, которые защищены и охраняются законодательством РФ в любой форме и на носителях,
								независимо от технологий, как существующих в настоящее время, так и созданных в будущем.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>6.2.</span>
								Пользователь соглашается с тем, что все материалы, представленные на веб-сайтах (онлайн-сервисах)
								третьих лиц, доступ к которым Пользователь получил с использованием функций онлайн-сервиса, принадлежит
								третьим лицам (их правообладателям).
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>6.3.</span>
								Пользователю запрещено без согласия Администрации осуществлять копирование или распространение любых
								объектов авторского права, правообладателем которых является Администрация, независимо от целей такого
								использования. За нарушение указанного запрета Пользователь несет ответственность, установленную
								законом.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>6.4.</span>
								Администрация, в случае установления факта использования объектов интеллектуальной собственности целиком
								или отдельной их части, вправе приостановить для Пользователя доступ к веб-сайту, а также обратиться в
								судебный орган для защиты своих прав с целью взыскания убытков.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>6.5.</span>В случае, если будет установлено, что Пользователь является
								участником и (или) распространителем результатов интеллектуальной деятельности Администрации,
								Пользователь обязан возместить Администрации причиненные убытки (в виде реальных убытков, упущенной
								выгоды, потерь деловой репутации) в срок 10 (десять) календарных дней с момента поступления требования.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>7. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.1.</span>
								Пользовательское соглашение вступает в силу одновременно с заключением Договора, путем акцепта Публичной
								оферты установленным способом, и является ее неотъемлемой частью.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.2.</span>
								Во всем ином, что не предусмотрено Договором и Пользовательским соглашением, стороны руководствуются
								законодательством Российской Федерации, а в случае отсутствия нормы права, регулирующей возникшие
								правоотношения, сложившимися обычаями делового оборота и научной доктриной.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.3.</span>
								Администрация вправе в одностороннем порядке и в любое время вносить изменения и (или) дополнения в
								Пользовательское соглашение путем внесения изменений (дополнений) к текущей редакции либо в форме
								принятия новой редакции, путем публикации указанных изменений на веб-сайте или отправки уведомления на
								адрес электронной почты.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.4.</span>
								Пользовательское соглашение составлено на русском языке и в соответствии с законодательством Российской
								Федерации. Подлинный экземпляр Пользовательского соглашения хранится по местонахождению Администрации. С
								целью обеспечения неограниченного доступа к нему, Пользовательское соглашение опубликовано в свободном
								доступе и круглосуточно на веб-сайте.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>8. РЕКВИЗИТЫ АДМИНИСТРАЦИИ</h6>
							<table className='flex w-full border border-solid font-bold'>
								<tr className='flex flex-col w-1/3 '>
									<th className='border border-solid p-2 text-start'>Наименование</th>
									<th className='border border-solid p-2 text-start'>ОГРН</th>
									<th className='border border-solid p-2 text-start'>ИНН</th>
									<th className='border border-solid p-2 text-start'>Юридический адрес</th>
									<th className='border border-solid p-2 text-start'>E-mail</th>
									<th className='border border-solid p-2 text-start'>Тел.</th>
								</tr>
								<tr className='flex flex-col w-2/3'>
									<td className='border border-solid p-2'>ООО «ЛАД-Интеллект»</td>
									<td className='border border-solid p-2'>1155260010960</td>
									<td className='border border-solid p-2'>5260414210</td>
									<td className='border border-solid p-2'>
										603093, г. Нижний Новгород, ул. Родионова, д.23А, офис 313
									</td>
									<td className='border border-solid p-2'>support@gptzator.ru</td>
									<td className='border border-solid p-2'>8 (831) 2-333-666</td>
								</tr>
							</table>
						</section>
					</div>
					<Button key='ok' type='primary' size='large' onClick={onConfirmHandler}>
						Закрыть
					</Button>
				</div>
			</Modal>
		);
	}
);
