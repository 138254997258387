const typeTemplate = 'Поле "${label}" должно являться типом "${type}"';
const len = 'Поле "${label}" должно содержать ${len} символов';
const min = 'Поле "${label}" должно содержать не менее ${min} символов';
const max = 'Поле "${label}" должно содержать не больше ${max} символов';
const range = 'Поле "${label}" должно содержать от ${min} до ${max} символов';

export const validateRuMessages = {
	required: '${label} не может быть пустым',
	string: {
		len,
		min,
		max,
		range
	},
	number: {
		len,
		min,
		max,
		range
	},
	array: {
		len,
		min,
		max,
		range
	},
	types: {
		string: typeTemplate,
		method: typeTemplate,
		array: typeTemplate,
		object: typeTemplate,
		number: typeTemplate,
		date: typeTemplate,
		boolean: typeTemplate,
		integer: typeTemplate,
		float: typeTemplate,
		regexp: typeTemplate,
		email: typeTemplate,
		url: typeTemplate,
		hex: typeTemplate
	}
};
