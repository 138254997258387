import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import remarkRehype from 'remark-rehype';
import remarkGfm from 'remark-gfm';
import rehypeStringify from 'rehype-stringify';
import remarkPlantUml from './remarkPlantUml';
import rehypeMermaid from 'rehype-mermaid';
import rehypeParse from 'rehype-parse';
import rehypePrettyCode from 'rehype-pretty-code';
import { getPlantumlUrl, getChartUrl } from '../env';
import remarkChart from './remarkChart';
import remarkBase64ToImg from './remarkBase64ToImg';

export const mdToHtml = async (markdown: string) => {
	const plantUmlUrl = getPlantumlUrl();
	const chartUrl = getChartUrl();
	try {
		const result = await unified()
			.use(rehypeParse, { fragment: true })
			.use(remarkParse)
			.use(remarkMath)
			.use(remarkGfm)
			.use(remarkRehype)
			.use(remarkBase64ToImg)
			.use(remarkChart, { baseUrl: chartUrl })
			.use(remarkPlantUml, { baseUrl: plantUmlUrl })
			.use(rehypeMermaid, { strategy: 'img-png' })
			.use(rehypePrettyCode, { theme: 'light-plus', defaultLang: 'plaintext' })
			.use(rehypeKatex)
			.use(rehypeStringify)
			.process(markdown);
		return result.toString();
	} catch (e) {
		return markdown;
	}
};
