import React, { memo, MouseEventHandler, useState } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslate } from '../../../../shared/lib/i18n';
import { RenewalOptionsModal } from '../RenewalOptionsModal';
import { WalletBg } from '../../../../lib/getImg';

type TEmptyTokensBannerProps = {
	closeBannerHandler?: () => void;
	closable: boolean;
};

export const EmptyTokensBanner = memo(({ closeBannerHandler, closable }: TEmptyTokensBannerProps) => {
	const t = useTranslate();
	const bgImage = {
		backgroundImage: `url(${WalletBg})`
	};
	const [open, setOpen] = useState<boolean>(true);
	const [isRenewalOptionsModalOpen, setIsRenewalOptionsModalOpen] = useState<boolean>(false);
	const onCloseClick = () => {
		setOpen(false);
		closeBannerHandler!();
	};
	const onReadMoreClick = () => {
		setIsRenewalOptionsModalOpen(true);
	};
	return open ? (
		<div className='min-h-[38px] relative bg-gray-bg'>
			<div
				className='bg-gray-bg min-h-[38px] py-6 pl-9 pr-28 mr-4 bg-no-repeat bg-[right_bottom_0px] bg-[length:112px]'
				style={bgImage}
			>
				<div>
					<h3>{t('not_enough_tokens')}</h3>
					<span className='opacity-40'>{t('continue_using_instruction')}.</span>
					<span
						className='text-primary-active hover:underline underline-offset-2 pl-1 cursor-pointer'
						onClick={onReadMoreClick}
					>
						{t('read_more')}
					</span>
				</div>
			</div>
			{closable && (
				<Button
					type='text'
					icon={<CloseOutlined className='text-xs' />}
					onClick={onCloseClick}
					className='absolute right-3 top-3'
				></Button>
			)}
			<RenewalOptionsModal
				isRenewalOptionsModalOpen={isRenewalOptionsModalOpen}
				closeRenewalOptionsModal={() => setIsRenewalOptionsModalOpen(false)}
			/>
		</div>
	) : null;
});
