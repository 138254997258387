import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from '../Message.module.css';

import { UserOutlined } from '@ant-design/icons';
import { TMessage } from '../../../model/types';
import { ThreeDotsIcon, EditOutlinedIcon, DeleteOutlined, CopyOutlinedIcon } from 'shared/ui/Icons';
import { Button, Dropdown, MenuProps, message as antMessage, Popconfirm } from 'antd';
import { useTranslate } from '@ayub-begimkulov/i18n';
import {
	codeBlockPlugin,
	codeMirrorPlugin,
	headingsPlugin,
	linkPlugin,
	listsPlugin,
	markdownShortcutPlugin,
	MDXEditor,
	MDXEditorMethods,
	quotePlugin,
	thematicBreakPlugin
} from '@mdxeditor/editor';
import cn from 'classnames';

interface IUserMessage {
	message: TMessage;
	avatarUrl?: string;
	disabled?: boolean;
	handleSave?: (content: string, messageId: string) => void;
	handleDelete?: (messageId: string) => void;
}

const UserMessageComponent = ({ message, avatarUrl, handleSave, handleDelete, disabled }: IUserMessage) => {
	const { choices } = message;
	const t = useTranslate();
	const [messageApi, contextMessageHolder] = antMessage.useMessage();
	const contentRef = useRef<HTMLDivElement>(null);
	const messsageMdxRef = useRef<MDXEditorMethods>(null);
	const [newMessage, setNewMessage] = useState<string>('');
	const [isEdited, setIsEdited] = useState(false);

	const handleEdit = () => {
		setIsEdited((prev) => !prev);
		messsageMdxRef.current?.focus();
	};

	const onCopyClick = useCallback(async () => {
		if (contentRef.current) {
			const html = contentRef.current.innerHTML;

			try {
				await navigator.clipboard.write([
					new ClipboardItem({
						'text/html': new Blob([html], { type: 'text/html' }),
						'text/plain': new Blob([contentRef.current.innerText], { type: 'text/plain' })
					})
				]);
				messageApi.success({
					content: <span>{t('copied')}</span>,
					duration: 3
				});
			} catch (err) {
				messageApi.error({
					content: <span>{t('copied.error')}</span>,
					duration: 3
				});
			}
		}
	}, [choices, contentRef.current]);

	const onSaveClick = useCallback(async () => {
		if (!newMessage) return;

		try {
			setNewMessage(newMessage);
			await handleSave?.(newMessage, message.id);
		} catch (e) {
			messageApi.error({
				content: <span>{t('something_wrong')}</span>,
				duration: 5
			});
		} finally {
			setIsEdited(false);
		}
	}, [newMessage, isEdited]);

	useEffect(() => {
		const messageText: string[] = [];
		choices.map((contentItem) => {
			messageText.push(contentItem.content);
		});
		setNewMessage(messageText.join('\n'));
	}, [message]);

	useEffect(() => {
		if (disabled) {
			setIsEdited(false);
		}
	}, [disabled]);

	useEffect(() => {
		messsageMdxRef.current?.focus();
	}, [isEdited]);

	const items: MenuProps['items'] = useMemo(
		() => [
			{
				label: (
					<a
						onClick={(e) => {
							e.preventDefault();
							handleEdit();
						}}
					>
						<EditOutlinedIcon />
						<span className='ml-2'>{t('edit')}</span>
					</a>
				),
				key: 'edit'
			},
			{
				label: (
					<Popconfirm
						title={<span>{t('delete_message.request_confirm')}?</span>}
						onConfirm={(e) => {
							e?.preventDefault();
							e?.stopPropagation();
							handleDelete?.(message.id);
						}}
						onCancel={(e) => {
							e?.preventDefault();
							e?.stopPropagation();
						}}
						arrow={false}
						cancelButtonProps={{ type: 'text' }}
						okText={t('delete')}
						cancelText={t('cancel')}
					>
						<a
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<DeleteOutlined className='text-error' />
							<span className='text-error ml-2'>{t('delete')}</span>
						</a>
					</Popconfirm>
				),
				key: 'delete'
			}
		],
		[]
	);

	return (
		<div className={cx.root}>
			{contextMessageHolder}
			<div className='w-full max-w-[800px] flex justify-end'>
				<div
					className={cn(`${cx.inner} !rounded-br-none`, {
						'pointer-events-none opacity-60': disabled
					})}
				>
					{avatarUrl ? (
						<div className='rounded-full hidden md:flex item-center justify-center min-w-[32px] w-[32px] h-[32px] overflow-hidden mr-4'>
							<img src={avatarUrl} alt='avatar' style={{ width: '100%' }} />
						</div>
					) : (
						<UserOutlined className='min-w-[32px] h-[32px] rounded-full border border-solid border-primary text-primary hidden md:flex items-center justify-center text-3xl overflow-hidden mr-4' />
					)}
					<div className='flex flex-col w-full pl-0'>
						<div className='flex items-center gap-x-2 absolute right-4 top-4'>
							<div className='min-w-[20px] w-[20px] h-[20px] flex items-center justify-center opacity-25'>
								<CopyOutlinedIcon className={cx.copy} onClick={() => onCopyClick()} />
							</div>
							<Dropdown menu={{ items }} trigger={['click']}>
								<span onClick={(e) => e.preventDefault()} className='flex items-center'>
									<ThreeDotsIcon className='rotate-90 cursor-pointer text-lg' />
								</span>
							</Dropdown>
						</div>
						<div ref={contentRef}>
							{choices.map((contentItem) => {
								return !isEdited ? (
									<div key={contentItem.id} className='max-w-full'>
										{contentItem.content}
									</div>
								) : (
									<div className='flex flex-col items-end'>
										<MDXEditor
											className='w-full'
											contentEditableClassName='outline-primary-active bg-white p-1 w-full'
											key={contentItem.id}
											onChange={setNewMessage}
											plugins={[
												headingsPlugin(),
												listsPlugin(),
												linkPlugin(),
												quotePlugin(),
												thematicBreakPlugin(),
												markdownShortcutPlugin(),
												codeBlockPlugin({ defaultCodeBlockLanguage: 'plaintext' }),
												codeMirrorPlugin({
													codeBlockLanguages: {
														javascript: 'javascript',
														JavaScript: 'JavaScript',
														js: 'js',
														typescript: 'typescript',
														typeScript: 'typeScript',
														ts: 'ts',
														jsx: 'jsx',
														Jsx: 'Jsx',
														tsx: 'tsx',
														Tsx: 'Tsx',
														css: 'CSS',
														json: 'JSON',
														markdown: 'markdown',
														mermaid: 'mermaid',
														python: 'python',
														Python: 'Python',
														plaintext: 'plaintext',
														java: 'java',
														Java: 'Java'
													}
												})
											]}
											markdown={
												typeof contentItem.content === 'string'
													? contentItem.content
															.replace(/\n\s*\d+(\.\d+)+\.\s/g, '\n\n$&')
															.replace(/\n(\d+\.) /g, '\n\n$1 ')
													: JSON.stringify(contentItem.content, null, 2)
											}
											ref={messsageMdxRef}
										/>
										<div className='mt-4'>
											<Button type='text' onClick={() => setIsEdited(false)}>
												{t('cancel')}
											</Button>
											<Button type='primary' onClick={() => onSaveClick()}>
												{t('save')}
											</Button>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const UserMessage = memo(UserMessageComponent);
