import React, { memo } from 'react';
import { EAppFormFields, TAppFormFields } from '../../../model/types';
import { Form, Input } from 'antd';
import { useTranslate } from 'shared/lib/i18n';

const IdeaFieldsComponent = ({ action, actionsSlot, onChange, path }: TAppFormFields) => {
	const t = useTranslate();
	return (
		<div className={action.selected ? '' : 'hidden'}>
			<div className='flex justify-between text-base font-medium'>{actionsSlot}</div>
			<Form.Item
				label={t('hint')}
				name={[...path, EAppFormFields.PLACEHOLDER]}
				initialValue={action.data[EAppFormFields.PLACEHOLDER]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.PLACEHOLDER], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.BLOCK_TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={EAppFormFields.NEXT_SLUG}
				name={[...path, EAppFormFields.NEXT_SLUG]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={EAppFormFields.SLUG}
				name={[...path, EAppFormFields.SLUG]}
				initialValue={action.data[EAppFormFields.SLUG]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
			</Form.Item>
		</div>
	);
};

export const IdeaFields = memo(IdeaFieldsComponent);
