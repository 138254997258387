import { Route, Routes } from 'react-router-dom';

import { PrivateProvider } from '../providers/PrivateProvider';
import { RouterConfig, TRouteItem } from './config';

export const getRoutes = (el: TRouteItem) => {
	if (el?.element && el?.children?.length) {
		return (
			<Route
				key={el.id}
				path={el.path}
				element={
					el.private ? (
						<PrivateProvider>
							<el.element />
						</PrivateProvider>
					) : (
						<el.element />
					)
				}
			>
				{el.children.map((child: TRouteItem) => getRoutes(child))}
			</Route>
		);
	}

	if (el?.element && el?.private) {
		return (
			<Route
				key={el.id}
				path={el.path}
				index={el.index}
				element={
					<PrivateProvider>
						<el.element />
					</PrivateProvider>
				}
			/>
		);
	}

	return el?.element && <Route index={el.index} key={el.id} path={el.path} element={<el.element />} />;
};

export const AppRouter = () => <Routes>{RouterConfig.map((el) => getRoutes(el))}</Routes>;
