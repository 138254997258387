import React, { memo } from 'react';
import cx from '../Message.module.css';

import { PaperClipOutlined } from 'shared/ui/Icons';

import cn from 'classnames';

interface IFileMessage {
	fileName: string;
	disabled?: boolean;
}

const FileMessageComponent = ({ fileName, disabled }: IFileMessage) => {
	return (
		<div className={cx.root}>
			<div className='w-full max-w-[800px] flex relative justify-end'>
				<div
					className={cn(`${cx.inner} !rounded-br-none`, {
						'pointer-events-none opacity-60': disabled
					})}
				>
					<PaperClipOutlined className='opacity-[0.45] text-2xl text-black' />

					<div className='ml-2 flex flex-col w-full pl-0'>{fileName}</div>
				</div>
			</div>
		</div>
	);
};

export const FileMessage = memo(FileMessageComponent);
