import { baseApi } from 'shared/api/baseApi';
import { APP_TAG, APP_TAGS_TAG, APPS_LIST_TAG, FAVOURITE_APPS_LIST_TAG, OAUTH_CLIENTS_TAG } from 'shared/api/tags';
import { TApp, TOauthClient } from '../model/types';
import { TAppsDTO, TTagsDTO } from './types';
import qs from 'qs';
import { HELPERS_PER_PAGE, TAGS_AMOUNT } from '../model/constants';
import { setAppsList, updateAppIsFavourite, updateAppsList } from '../model/slice';

export const appsApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getApps: build.query<TAppsDTO, { tag?: string; search?: string; page: number; author?: string }>({
			query: ({ tag, search, page, author }) => {
				let query: any = {
					name: {
						contains: search
					}
				};
				if (tag) {
					query = {
						...query,
						tags: {
							equals: tag
						}
					};
				}
				if (author) {
					query = {
						...query,
						author: {
							equals: author
						}
					};
				}
				const stringifiedQuery = qs.stringify(
					{
						depth: 0,
						where: query,
						limit: HELPERS_PER_PAGE,
						page
					},
					{ addQueryPrefix: true }
				);

				return {
					url: `apps${stringifiedQuery}`
				};
			},
			onQueryStarted: async (payload, { dispatch, getState, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled;
					if (payload.page !== 1) {
						dispatch(updateAppsList(data.docs));
					} else {
						dispatch(setAppsList(data.docs));
					}
				} catch (err) {
					console.error(err);
				}
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			},
			merge: (currentCache, newItems, { arg }) => {
				if (arg.page !== 1) {
					return {
						...currentCache,
						...newItems,
						docs: [...currentCache.docs, ...newItems.docs]
					};
					currentCache.docs.push(...newItems.docs);
				}
				return newItems;
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: [APPS_LIST_TAG]
		}),
		createApp: build.mutation<
			TApp,
			{
				name: string;
				description?: string;
				author: string;
				placeholder?: string;
				tags?: string[];
				emoji?: string;
				descriptionSmall?: string;
			}
		>({
			query: ({ name, author, description, tags, placeholder, emoji, descriptionSmall }) => {
				return {
					url: 'apps?depth=0',
					method: 'POST',
					data: {
						name,
						author,
						...(description ? { description } : {}),
						...(descriptionSmall ? { descriptionSmall } : {}),
						...(placeholder ? { placeholder } : {}),
						...(emoji ? { emoji } : {}),
						...(tags ? { tags } : {})
					}
				};
			},
			transformResponse: (response: { doc: TApp; message: string }) => {
				return response.doc;
			}
		}),
		updateApp: build.mutation<TApp, { id: string; data: any }>({
			query: ({ id, data }) => {
				return {
					url: `apps/${id}?depth=0`,
					method: 'PATCH',
					data
				};
			},
			invalidatesTags: [APPS_LIST_TAG, APP_TAG]
		}),
		deleteApp: build.mutation<TAppsDTO, string>({
			query: (id) => {
				const stringifiedQuery = qs.stringify(
					{
						depth: 0,
						where: {
							id: {
								equals: id
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `apps${stringifiedQuery}`,
					method: 'DELETE'
				};
			},
			invalidatesTags: [APPS_LIST_TAG, FAVOURITE_APPS_LIST_TAG]
		}),
		getAppById: build.query<TApp, string>({
			query: (id) => ({
				url: `apps/${id}?depth=0`
			}),
			providesTags: [APP_TAG]
		}),
		getOauthClients: build.query<{ clients: TOauthClient[] }, string>({
			query: (redirectUri) => ({
				url: `oauth_clients/oauth/clients?redirectUri=${redirectUri}`
			}),
			providesTags: [OAUTH_CLIENTS_TAG]
		}),
		logoutOauthClient: build.mutation<TApp, string>({
			query: (client) => ({
				url: `oauth_clients/oauth/clients/${client}/logout`,
				method: 'POST'
			}),
			invalidatesTags: [OAUTH_CLIENTS_TAG]
		}),
		getFavouriteApps: build.query<TApp[], void>({
			query: () => ({
				url: `apps/favourites?depth=0`
			}),
			providesTags: [FAVOURITE_APPS_LIST_TAG]
		}),
		updateIsFavourite: build.mutation<void, { id: string; isFavourite: boolean }>({
			query: ({ id, isFavourite }) => {
				return isFavourite
					? {
							url: 'users/favourites',
							method: 'POST',
							data: { id }
					  }
					: {
							url: `users/favourites/${id}`,
							method: 'DELETE'
					  };
			},
			async onQueryStarted({ id, isFavourite }, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(updateAppIsFavourite({ id, isFavourite }));
				} catch (err) {
					console.error(err);
				}
			},
			invalidatesTags: [APPS_LIST_TAG, FAVOURITE_APPS_LIST_TAG]
		}),
		getTags: build.query<TTagsDTO, void>({
			query: () => {
				const stringifiedQuery = qs.stringify(
					{
						limit: TAGS_AMOUNT,
						page: 1
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `tags${stringifiedQuery}`
				};
			},
			providesTags: [APP_TAGS_TAG]
		})
	})
});

export const {
	useGetAppsQuery,
	useLazyGetAppsQuery,
	useGetAppByIdQuery,
	useLazyGetAppByIdQuery,
	useGetTagsQuery,
	useGetFavouriteAppsQuery,
	useUpdateIsFavouriteMutation,
	useCreateAppMutation,
	useUpdateAppMutation,
	useDeleteAppMutation,
	useGetOauthClientsQuery,
	useLogoutOauthClientMutation
} = appsApi;
