/* eslint-disable */
import { memo } from 'react';
import { Button, Modal } from 'antd';
import { useTranslate } from '../../lib/i18n';

interface IPublicOfferModalProps {
	isPublicOfferModalOpen: boolean;
	closePublicOfferModalHandler: () => void;
}

export const PublicOfferModal = memo(
	({ isPublicOfferModalOpen, closePublicOfferModalHandler }: IPublicOfferModalProps) => {
		const t = useTranslate();
		const onConfirmHandler = () => {
			closePublicOfferModalHandler();
		};

		return (
			<Modal
				open={isPublicOfferModalOpen}
				width={850}
				centered
				onCancel={onConfirmHandler}
				onOk={onConfirmHandler}
				footer={null}
			>
				<div className='max-w-[850px] max-h-[600px] py-7 grow bg-white sm:p-10 p-7 w-full rounded-2xl flex flex-col items-center text-charcoal-light overflow-y-auto'>
					<div className='w-full'>
						<div className='w-full flex flex-col items-center'>
							<h1 className='w-full px-24 text-3xl text-center'>ОНЛАЙН-СЕРВИС "GPTZATOR"</h1>
							<p className='font-bold text-3xl mb-2'>ПУБЛИЧНЫЙ ДОГОВОР ⎯ ОФЕРТА</p>
							<p className='font-bold text-xl mb-2'>о заключении Лицензионного договора</p>
						</div>
						<div className='w-full flex flex-col items-center mb-7'>
							<p className='font-bold mb-2'>(далее по тексту – «Публичная оферта»)</p>
							<p className='font-bold'>Версия от «22» декабря 2023 г., Российская Федерация, г. Нижний Новгород</p>
						</div>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>1. ПРЕАМБУЛА. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.1.</span>
								Публичная оферта является официальным публичным предложением ОБЩЕСТВА С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ
								«ЛАД-Интеллект», в лице Генерального директора Петрова Дмитрия Николаевича, действующего на основании
								Устава (далее по тексту ⎯ "Администрация"), с одной стороны, адресованное к неограниченному кругу
								физических лиц, обладающих полной дееспособностью, в т. ч. зарегистрированных в качестве индивидуального
								предпринимателя или плательщика налога на профессиональный доход, а также юридических лиц, от имени и в
								интересах которых действуют уполномоченные представители, обладающие достаточным объемом прав и
								полномочий (далее по тексту ⎯ "Пользователь"), с другой стороны, вместе в дальнейшем именуемые ⎯
								"Стороны", а каждый индивидуально ⎯ "Сторона", заключить на одинаковых для всех условиях и в электронном
								виде Лицензионный договор (далее по тексту ⎯ "Договор"), на нижеизложенных условиях.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.2.</span>
								Уполномоченные представители юридических лиц или индивидуальные предприниматели обращаются к
								Администрации за коммерческим предложением по адресу электронной почты:{' '}
								<a
									className='text-primary hover:text-primary hover:underline underline-offset-2'
									href='mailto:support@gptzator.ru'
								>
									support@gptzator.ru.
								</a>
								.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.3.</span>
								Заключение Договора осуществляется посетителем веб-сайта в момент принятия условий Публичной оферты
								путем совершения акцепта (п. 2 ст. 437 ГК РФ, п. 3 ст. 438 ГК РФ), в результате чего, указанное лицо
								становится Пользователем в рамках Договора заключенного с Администрацией. Акцепт равносилен заключению
								Договора в простой письменной форме на условиях, изложенных в Публичной оферте, без подписания сторонами
								его письменного экземпляра, что создает для сторон взаимные права и обязанности, за неисполнение
								которых, наступает установленная законом ответственность.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.4.</span>
								Фиксация факта совершения акцепта и заключения Договора осуществляется в электронном виде и хранится в
								аппаратно-программном комплексе Администрации. Выписки из аппаратно-программного комплекса могут
								использоваться Администрацией в качестве доказательств в простой письменной или электронной формах при
								рассмотрении споров, в том числе в судебном порядке.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.5.</span>
								Актуальная редакция Публичной оферты опубликована Администрацией на веб-сайте (кнопка "Публичная оферта"
								в футере) и круглосуточно доступна для любого посетителя, который обязан внимательно ознакомиться с ее
								условиями перед совершением акцепта. Если Пользователь не согласен с Публичной офертой целиком либо ее
								отдельными частями, Администрация настоятельно призывает такого Пользователя воздержаться от совершения
								акцепта и оплаты стоимости доступа к онлайн-сервису, так как акцепт предполагает безоговорочное согласие
								Пользователя с условиями Договора.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.6.</span>
								Пользователь обязан внимательно ознакомиться с условиями использования искусственного интеллекта
								(нейросетей) и других онлайн-ресурсов, принадлежащих третьим лицам, доступ к которым Администрация
								предоставляет с использованием выделенных виртуальных серверов посредством настоящего онлайн-сервиса.
								Совершая акцепт, Пользователь подтверждает также и то, что им соблюдено указанное в настоящем пункте
								Договора обязательство.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.7.</span>
								Для целей применения и толкования Договора, Администрацией используются установленные ниже основные
								термины (если в Договоре прямо не указано иное). В тексте Договора эти термины могут быть использованы в
								другом падеже, в единственном или множественном числе, с маленькой или большой буквы, либо в виде
								сокращений:
								<ul className='list-none m-0 p-0 pl-5'>
									<li>
										<span className='mr-1 font-bold '>1.7.1. "Акцепт"</span>
										&mdash; полное и безоговорочное принятие Пользователем условий Публичной оферты путем совершения
										одного или нескольких конклюдентных действий с помощью веб-сайта: 1) активация специального флажка
										("чекбокса"): "Я ознакомлен (-на) и согласен (-на) с Публичной офертой и Политикой
										конфиденциальности, даю свое согласие на обработку моих персональных данных" в контрольном пункте
										специальной формы на веб-сайте; 2) Регистрация на веб-сайте; 3) Полная или частичная оплата
										вознаграждения за получение доступа и права использования онлайн-сервиса; 4) Использование
										онлайн-сервиса; 5) Иные встречные действия. Указанные конклюдентные действия, совершенные
										Пользователем по отдельности или в совокупности, имеют для сторон юридическую силу его письменного
										согласия на заключение Договора.
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.2. "Веб-сайт"</span>
										&mdash; совокупность программ для ЭВМ, баз данных, графических, аудиовизуальных и информационных
										материалов, содержащихся в информационной системе, доступ к которой обеспечивается посредством сети
										"Интернет", расположенный по постоянному URL адресу:{' '}
										<a
											className='text-primary hover:text-primary hover:underline underline-offset-2'
											href='https://gptzator.ru/'
										>
											https://gptzator.ru/
										</a>{' '}
										, включающий в себя все уровни указанного домена, как действующие, так и вводимые в эксплуатацию в
										течении всего срока его действия, а также исходящие от него страницы ("лендинги"). С помощью
										веб-сайта, Администрация предоставляет доступ к онлайн-сервису.
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.3. "Онлайн-сервис" "GPTZATOR"</span>
										(программное обеспечение) &mdash; интерактивная онлайн-платформа, обозначенная средствами
										индивидуализации, включая обновления к ней и дополнительные функциональные возможности,
										представленная в объективной форме совокупностью данных и команд, активируемых для получения
										определенного результата, включая порождаемые ею аудиовизуальные отображения, предназначенные для
										функционирования ЭВМ и других компьютерных устройств. Онлайн-сервис предоставляет для Пользователя
										возможность получения доступа к искусственным интеллектам (нейросетям) различных версий, которые по
										запросу Пользователя предоставляют основанную на таком запросе информацию в виде: текста,
										изображений, видео, аудио или в ином формате, а также возможность получения доступа к иным
										онлайн-сервисам по API, в целях получения определенного результата, в т. ч. по индивидуальным
										заявкам Пользователя после их проверки и обработки Администрацией на возможность реализации.
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.4. "Тариф" ("Тарифный план")</span>
										&mdash; форма коммерческого предложения, содержащая размер вознаграждения за предоставление доступа
										и права использования онлайн-сервиса, установленного Администрацией в зависимости от ключевых
										параметров прав доступа, пределов, объема, способов или срока предоставления прав доступа к
										онлайн-сервису. Разновидности Тарифного плана круглосуточно опубликованы на веб-сайте{' '}
										<a
											className='text-primary hover:text-primary hover:underline underline-offset-2'
											href='https://gptzator.ru/'
										>
											https://gptzator.ru/
										</a>{' '}
										в свободном доступе и являются неотъемлемой частью Договора.
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.5. "Рекуррентные платежи"</span>
										("Автоплатеж") &mdash; сервис по осуществлению регулярных автоматических переводов денежных средств
										с банковской карты Пользователя (в статусе физического лица) в пользу Администрации за
										предоставление права использования онлайн-сервиса, на основании заранее данного акцепта Пользователя
										(держателя карты).
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.6. "Учетная запись"</span>
										("Аккаунт") &mdash; хранимая в компьютерной системе уникальная совокупность регистрационных данных о
										Пользователе, содержащая информацию необходимую для его аутентификации и авторизации на веб-сайте, а
										также информацию о персональных данных и предоставленных правах доступа.
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.7. "Личный кабинет"</span>
										&mdash; специальный (персональный) раздел веб-сайта, доступный Пользователю после регистрации и
										авторизации на веб-сайте, содержащий информацию о правах доступа к онлайн-сервису, статистике и
										уведомлениях, совершении иных действий, связанных с использованием онлайн-сервиса.
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.8. "Логин"</span>
										&mdash; уникальное имя учетной записи, определяемое Пользователем в момент регистрации, которым
										выступает адрес электронной почты, необходимое для аутентификации и авторизации на веб-сайте с целью
										получения доступа к личному кабинету.
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.9. "Пароль"</span> &mdash; секретный набор символов, не
										подлежащих разглашению третьим лицам, определяемых Пользователем на веб-сайте в момент регистрации и
										вводимых в момент прохождения процедуры аутентификации и авторизации учетной записи. Администрация
										вправе установить ограничения к содержанию пароля (минимальное количество символов, знаков, формат,
										кодировка, безопасность).
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.10. "Аутентификация"</span>
										&mdash; процедура проверки подлинности авторизационных данных Пользователя путем сравнения
										введенного им в специальную форму логина и пароля с логином и паролем, сохраненным в системе
										безопасности в момент его регистрации на веб-сайте.
									</li>
									<li>
										<span className='mr-1 font-bold '>1.7.11. "Авторизация"</span>
										&mdash; предоставление прав доступа к аккаунту, личному кабинету и непосредственно к онлайн-сервису,
										после прохождения Пользователем процедуры регистрации и аутентификации с помощью веб-сайта.
									</li>
								</ul>
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.8.</span>
								Договор может содержать иные термины, при толковании которых, принимается во внимание буквальное
								значение содержащихся в нем слов и выражений. Во всех иных случаях, толкование терминов осуществляется в
								соответствии с законодательством Российской Федерации, обычаями делового оборота или научной доктриной.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.9.</span>
								Предложение о заключении Договора не может быть отозвано Пользователем самостоятельно и действует до
								даты его отзыва Администрацией при условии фактической доступности и работоспособности веб-сайта, за
								исключением случаев, предусмотренных Договором и законодательством Российской Федерации.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>1.10.</span>
								Срок действия Договора исчисляется со дня совершения акцепта Публичной оферты и заканчивается в день
								исчерпания Пользователем предоставленного ему в выбранном Тарифном плане объема прав доступа.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>2. ПРЕДМЕТ ДОГОВОРА</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.1.</span>В порядке и на условиях предусмотренных Договором,
								Администрация на возмездной основе предоставляет Пользователю право использования онлайн-сервиса на
								условиях простой (неисключительной) лицензии, в рамках его функциональных возможностей, без права
								сублицензирования третьим лицам, а Пользователь обязуется использовать онлайн-сервис в установленных
								пределах и предусмотренным способом, а также уплатить Администрации вознаграждение за использование
								онлайн-сервиса в размере выбранного Тарифного плана.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.2.</span>
								Способы использования онлайн-сервиса, предоставляемые Пользователю: право на воспроизведение на
								компьютерном устройстве, формирование запросов к искусственным интеллектам (нейросетям) в целях
								получения ответов (информации), а также запросов к другим онлайн-сервисам в целях активации
								неактивированных данных и команд, возможность изменения настроек в пределах, доступных в личном
								кабинете, право на адаптацию, внесение изменений, осуществляемых в пределах функциональных возможностей
								онлайн-сервиса, в целях получения определенного результата на конкретном устройстве.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.3.</span>Администрация является единственным правообладателем
								онлайн-сервиса, в связи с чем, права на онлайн-сервис для Пользователя ограничены "правом на
								использование", а доступ к онлайн-сервису предоставляется Пользователю "как есть", то есть порядок
								использования онлайн-сервиса возможен только тем способом, который устанавливает Администрация в
								опубликованных на веб-сайте документах, регламентирующих деятельность онлайн-сервиса.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>2.4.</span>
								Администрация вправе временно приостановить для Пользователя доступ к онлайн-сервису по техническим,
								технологическим или иным причинам на срок не более 24 часов. В случае более продолжительного ограничения
								доступа к онлайн-сервису, Пользователь вправе затребовать от Администрации перерасчета уплаченного
								вознаграждения пропорционально сроку, в течение которого онлайн-сервис был недоступен.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>3. ВОЗНАГРАЖДЕНИЕ И ПОРЯДОК РАСЧЕТОВ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.1.</span>Пользователь обязуется уплатить Администрации
								вознаграждение за предоставление права использования онлайн-сервиса в размере 100% от цены выбранного
								Тарифного плана, которая опубликована Администрацией на веб-сайте в момент оплаты.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.2.</span>
								Уплата вознаграждения производится Пользователем (в статусе физического лица) в рублях Российской
								Федерации, с помощью подключенной к веб-сайту системы интернет-эквайринга, путем совершения безналичного
								платежа с помощью собственной банковской карты, а Пользователем (в статусе ИП или ООО) путем
								безналичного перевода платежными поручениями на расчетный счет Администрации на основании выставленного
								счета.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.3.</span>
								Уплата вознаграждения с помощью системы интернет-эквайринга происходит путем ввода Пользователем
								достоверных платежных данных в специальную форму по ссылке, полученную на веб-сайте, за достоверность
								которых, Пользователь несет персональную ответственность. Если указанные платежные данные являются
								верными и действительными, а использование банковской карты будет признано технически возможным,
								происходит безналичное списание денежных средств с банковской карты Пользователя.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.4.</span>
								Администрация может устанавливать иные способы уплаты вознаграждения за право использования
								онлайн-сервиса на свое усмотрение. В этом случае, общая цена Договора может быть увеличена на сумму
								комиссии, установленной финансовыми учреждениями в зависимости от согласованного способа платежа, если
								иное не предусмотрено условиями специальной акции.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.5.</span>
								Сумма вознаграждения зачисляется на внутренний баланс Пользователя на веб-сайте и отображается на нем в
								виде GPTZ, которые подлежат списанию в пользу Администрации после использования платного функционала
								онлайн-сервиса, о чем Пользователь уведомляется с помощью подсказок на веб-сайте.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.6.</span>Сумма вознаграждения за использование онлайн-сервиса НДС не
								облагается.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.7.</span>
								Пользователь не вправе начислять или требовать от Администрации проценты за пользование суммой денежных
								средств, внесенных Пользователем в качестве вознаграждения за право использования онлайн-сервиса или в
								целях пополнения внутреннего баланса.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.8.</span>Администрация не контролирует аппаратно-программный
								комплекс системы интернет-эквайринга, интернет-банкинга, и не несет ответственность за ошибки в части
								обработки платежа финансовым учреждением. В случае отказа в принятии оплаты или отсутствия зачисления
								денежных средств на расчетный счет Администрации, обязанность по возврату денежных средств Пользователю
								лежит на указанных финансовых учреждениях, при содействии Администрации, в рамках доступных ей
								возможностей.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.9.</span>
								При уплате вознаграждения Пользователь (в статусе физического лица) обязан использовать собственную
								банковскую карту в соответствии с положением Центрального Банка РФ "Об эмиссии банковских карт и об
								операциях, совершаемых с использованием платежных карт" №266-П от 24.12.2004, а Пользователь (в статусе
								ИП или ООО) обязан производить оплату с собственного расчетного счета.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.10.</span>В случае, если оплату вознаграждения осуществляет третье
								лицо, вне зависимости от правового статуса Пользователя, обязательным условием совершения оплаты
								является указание в назначении платежа ФИО или полного наименования лица, в интересах которого
								осуществляется оплата.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.11.</span>
								Обязательство Пользователя по уплате Администрации вознаграждения за предоставление доступа к
								онлайн-сервису считается исполненным в момент зачисления денежных средств на расчетный счет
								Администрации и списания эквивалентной суммы с внутреннего баланса Пользователя в личном кабинете.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.12.</span>
								Администрация может изменять стоимость Тарифного плана в одностороннем порядке, размещая актуальную
								информацию на веб-сайте. После оплаты Пользователем суммы вознаграждения, новая стоимость Тарифного
								плана для Пользователя, уже оплатившего его стоимость, изменению не подлежит.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.13.</span>
								Уплата Пользователем вознаграждения за предоставление доступа к онлайн-сервису является самостоятельным
								способом совершения акцепта и означает, что Пользователь ознакомлен (-на) и согласен (-на) с Публичной
								офертой, имеет законное и добровольное намерение получить доступ к онлайн-сервису и использовать его в
								установленных пределах.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.14.</span>
								Возврат вознаграждения за использование онлайн-сервиса в предусмотренных случаях производится
								Администрацией на основании личного заявления Пользователя либо уполномоченного им лица на банковскую
								карту (расчетный счет), с которого осуществлялась оплата вознаграждения.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.15.</span>
								Администрация вправе предусмотреть для Пользователей скидки, бонусы, акционные предложения и иные
								льготные условия доступа к онлайн-сервису. Данные условия могут быть предусмотрены: специальной акцией,
								реферальной программой (кодом или ссылкой), получением промокода, иными индивидуальными случаями на
								усмотрение Администрации.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.16.</span>
								Предложение в рамках действующей акции или иных льготных условий предоставления доступа к онлайн-сервису
								ограничено сроком их проведения, о чем Администрация информирует Пользователя посредством веб-сайта или
								иных средств связи.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>3.17.</span>В случае неоплаты вознаграждения за предоставление права
								использования онлайн-сервиса, доступ к нему блокируется до момента устранения нарушения.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>4. РЕКУРРЕНТНЫЕ ПЛАТЕЖИ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.1.</span>Данные банковской карты Пользователя (в статусе физического
								лица) могут быть привязаны Администрацией к веб-сайту в момент перехода к оплате вознаграждения за
								использование онлайн-сервиса, путем введения данных банковской карты: номер банковской карты, срок
								окончания действия банковской карты, защитный код (CVV/CVC). Если указанные данные являются верными и
								действительными, а использование банковской карты будет признано технически возможным, указанная
								банковская карта получает статус Привязанной карты.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.2.</span>
								Пользователь (в статусе физического лица) понимает и соглашается с тем, что Привязанная карта может быть
								использована Администрацией в целях осуществления регулярных платежей в соответствии с оформленной
								подпиской, для последующей безналичной оплаты вознаграждения за использование онлайн-сервиса без
								необходимости дополнительного ввода данных банковской карты и подтверждения платежа.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.3.</span>
								Оплата вознаграждения за использование онлайн-сервиса является для Пользователя (в статусе физического
								лица) согласием на подключение услуги "Автоплатеж", выступает аналогом его собственноручной подписи, в
								следствие чего, автоматически подключается услуга "Автоплатеж". Начиная со второго месяца после
								совершения Пользователем (в статусе физического лица) первичной оплаты, с его банковской карты
								производится автоматическое списание денежных средств в размере стоимости ежемесячной подписки на
								выбранный Тарифный план.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.4.</span>
								Услуга "Автоплатеж" позволяет Пользователю (в статусе физического лица) оплачивать вознаграждение за
								использование онлайн-сервиса путём регулярных автоматических списаний (переводов) денежных средств в
								пользу Администрации со своей банковской карты. Непосредственное списание денежных средств осуществляет
								банк-эмитент на основании заранее данного согласия Пользователя (в статусе физического лица) на списание
								на условиях, предусмотренных подпиской на выбранный Тарифный план. Запрос на перевод денежных средств, с
								указанием суммы перевода, в банк-эмитент передается банком и процессинговым центром в рамках заключенных
								с Администрацией соглашений.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.5.</span>
								Пользователь (в статусе физического лица), принимая условия Публичной оферты, дает поручение составлять
								от его имени распоряжения о списании денежных средств за использование онлайн-сервиса со счета (к
								которому привязана Привязанная карта) Пользователя (в статусе физического лица) в пользу Администрации в
								соответствии с условиями подписки на выбранный Тарифный план, а также направлять распоряжения в
								банк-эмитент Пользователя (в статусе физического лица) через банк-эквайер.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.6.</span>Пользователь (в статусе физического лица) дает свое
								согласие и поручение Администрации на автоматическое, периодическое, в соответствии с выбранными
								Пользователем (в статусе физического лица) условиями оплаты (подпиской на Тарифный план), списание
								денежных средств с его счета (к которому привязана Привязанная карта) по его распоряжению, отданному в
								порядке, предусмотренном настоящим разделом Договора, и признает, что распоряжения на списание денежных
								средств с его счета, направленные в соответствии с настоящим разделом Договора, являются распоряжениями
								самого Пользователя (в статусе физического лица), а действия банка-эквайера, направленные на списание
								денежных средств в соответствии с настоящим пунктом Договора, выполнены с согласия и по поручению
								Пользователя (в статусе физического лица).
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.7.</span>
								Списание денежных средств в соответствии с настоящим разделом Договора начинает происходить на
								автоматической основе при соблюдении одновременно следующих условий: 1) Акцепт Публичной оферты; 2) Ввод
								Пользователем (в статусе физического лица) достаточных реквизитов Привязанной карты в форме окна оплаты;
								3) Нажатие кнопки "Оплатить" (или иной аналогичной по функционалу кнопки).
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.8.</span>В случае недостаточности денежных средств на банковской
								карте Пользователя для осуществления "Автоплатежа", Администрация имеет право повторять запрос на
								списание денежных средств с банковской карты Пользователя (в статусе физического лица) до момента
								списания необходимой суммы в полном объеме или считать указанное обстоятельство отказом Пользователя от
								исполнения обязательств в одностороннем порядке с даты невнесенного платежа.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.9.</span>
								Администрация не хранит и не обрабатывает данные банковских карт Пользователя, обеспечивая лишь запросы
								в процессинговый центр и банк для повторного проведения операции по банковской карте Пользователя.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.10.</span>Администрация не гарантирует возможность проведения
								операций по банковской карте Пользователя.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.11.</span>
								Отключение (отказ) Пользователя (в статусе физического лица) от услуги "Автоплатеж" происходит одним из
								следующих способов: 1) Активация кнопки "Удалить карту" в личном кабинете; 2) Перемещение
								соответствующего ползунка, рядом с кнопкой "Автоплатеж" в личном кабинете, в неактивное состояние; 3)
								Отправка электронного письма Администрации по адресу электронной почты:{' '}
								<a
									className='text-primary hover:text-primary hover:underline underline-offset-2'
									href='mailto:support@gptzator.ru'
								>
									support@gptzator.ru.
								</a>
								с темой письма "Отключение (отказ) от услуги "Автоплатеж" и требованием отключить услугу "Автоплатеж".
								Указанные действия имеют для сторон юридическую силу письменного отказа Пользователя (в статусе
								физического лица) от услуги "Автоплатеж".
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>4.12.</span>
								Денежные средства, переведенные с банковской карты до отключения (отказа) Пользователя (в статусе
								физического лица) от услуги "Автоплатеж", возврату не подлежат, кроме случаев, установленных Договором и
								законодательством Российской Федерации.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>5. УСЛОВИЯ ВОЗВРАТА</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>5.1.</span>
								Пользователь не имеет права требовать от Администрации возврата того, что было исполнено в соответствии
								с Договором. В случае надлежащего предоставления доступа к онлайн-сервису, а также права использования
								онлайн-сервиса в разрешенных Тарифным планом пределах, возврат суммы уплаченного вознаграждения
								Пользователю не производится.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>5.2.</span>
								Прекращение использования онлайн-сервиса Пользователем, вне зависимости от причины, само по себе не
								является основанием для возврата вознаграждения, как использованной его части, так и не использованной,
								а также не является основанием для неуплаты вознаграждения.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>5.3.</span>
								Возврат вознаграждения допускается исключительно по решению Администрации, в случае непредоставления или
								некачественного предоставления Пользователю доступа к онлайн-сервису, либо в случае запроса возврата
								денежных средств с внутреннего баланса Пользователя, при условии неиспользования онлайн-сервиса.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>6. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>6.1.</span>В случае возникновения спора, стороны подтверждают свою
								добровольную приверженность и стремление к урегулированию такого спора во внесудебном (досудебном)
								порядке. При этом, стороны принимают и соглашаются с тем, что досудебный порядок урегулирования спора с
								Пользователями в статусе физического лица не является для них обязательным, если иное не установлено
								законодательством Российской Федерации.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>6.2.</span>В случае наличия претензий Пользователь направляет в
								Администрацию претензию с помощью средств связи, предусмотренных Договором, в срок не более чем 10
								(десять) календарных дней с момента их выявления. Претензия должна содержать обстоятельства спора
								(нарушения), обоснование, ссылки на положения законодательства и условия Договора, конкретные
								требования, а также реквизиты сторон в объеме, достаточном для идентификации сторон и отправки ответа на
								претензию.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>6.3.</span>
								Администрация обязана предоставить мотивированный ответ на претензию в срок не более, чем 10 (десять)
								календарных дней с момента получения претензии. По результатам рассмотрения претензии, Администрация
								принимает одно из следующих решений: 1) удовлетворить требования другой стороны и предоставить
								фактическое исполнение (устранение) нарушения; 2) отказать в удовлетворении требований другой стороны с
								обоснованием своего решения.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>6.4.</span>
								Если сторонами не достигнуто соглашение об урегулировании возникшего спора во внесудебном (досудебном)
								порядке, такой спор передается на рассмотрение в судебный орган в соответствии с установленными
								правилами подсудности.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>7. ОТВЕТСТВЕННОСТЬ СТОРОН</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.1.</span>
								Стороны несут гражданско-правовую ответственность за неисполнение или ненадлежащее исполнение принятых
								на себя обязательств по Договору, а также иные виды ответственности в соответствии с законодательством
								Российской Федерации, в зависимости от вида и степени допущенного нарушения иного действия
								(бездействия).
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.2.</span>
								Администрация ни при каких обстоятельствах не может нести ответственность перед Пользователем или любым
								иным лицом, связанным с Пользователем, за прямые, косвенные или случайные убытки, а также за упущенную
								выгоду. Общая совокупная ответственность Администрации перед Пользователем, за ущерб любого рода и типа,
								не должна превышать 1000 руб.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.3.</span>
								Администрация не несет ответственность за несоответствие онлайн-сервиса ожиданиям Пользователя или за
								его субъективную оценку. Такое несоответствие или субъективная, отрицательная оценка онлайн-сервиса, ни
								при каких обстоятельствах не может являться основанием считать предоставление доступа и права
								использования онлайн-сервиса ненадлежащим.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.4.</span>
								Администрация не несет ответственность перед Пользователем, а также публичными органами государственной
								власти Российской Федерации за информацию, предоставленную искусственным интеллектом (нейросетью), так
								как такая информация предоставляется исключительно по запросам Пользователя и по техническим
								возможностям не может быть подчинена воле Администрации.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.5.</span>
								Администрация не несет ответственность за неблагоприятные последствия, которые наступили для
								Пользователя в связи с предоставлением Администрации недостоверных, недостаточных сведений или их
								несвоевременным предоставлением, а также за технические проблемы, возникшие по вине третьих лиц.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.6.</span>
								Администрация не гарантирует круглосуточную и бесперебойную доступность онлайн-сервиса.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.7.</span>
								Пользователь несет полную ответственность за совместимость его устройства с онлайн-сервисом и
								возможность его использования. Администрация не принимает на себя обязательства по обеспечению
								Пользователя программно-техническими возможностями для использования онлайн-сервиса. В случае, если
								устройство Пользователя не позволяет ему использовать онлайн-сервис в полной мере или частично,
								Администрация не несет каких-либо обязательств по возмещению Пользователю стоимости оплаченного права на
								использование онлайн-сервиса.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>7.8.</span>
								Администрация вправе без дополнительного предупреждения, частично или полностью заблокировать
								(ограничить) для Пользователя доступ к онлайн-сервису, в случае однократного существенного нарушения им
								условий Договора или Пользовательского соглашения.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>8. ФОРС-МАЖОР</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>8.1.</span>
								Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств, взятых на
								себя по Договору в случае, если неисполнение или ненадлежащее исполнение таких обязательств явилось
								следствием действия непреодолимой силы, а именно: пожара, наводнения, землетрясения, забастовки, войны
								(военной или контртеррористической операции), террористических актов, действий органов государственной
								власти, в т. ч. иностранных государств, международных санкций, выхода из строя дата-центра
								Администрации, неполадок электросетей или сети "Интернет", а также других независящих от сторон
								обстоятельств, возникших после заключения Договора.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>8.2.</span>
								Сторона, которая не может выполнить обязательства по Договору в связи с действием непреодолимой силы,
								обязана своевременно, но не позднее следующего дня после наступления обстоятельств непреодолимой силы,
								письменно известить другую сторону, с предоставлением обосновывающих (подтверждающих такие
								обстоятельства) документов, в т. ч. выданных компетентными органами.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>9. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>9.1.</span>
								Правообладателем онлайн-сервисов третьих лиц, в том числе нейросетей и других онлайн-сервисов, доступ к
								которым Пользователь получил или желает получить с использованием настоящего онлайн-сервиса, являются
								третьи лица, информация о чем опубликована или должна быть опубликована правообладателями на своих
								интернет-ресурсах и правовых документах.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>9.2.</span>
								Авторские (личные неимущественные) права на веб-сайт и онлайн-сервис (право авторства, право автора на
								имя, право на неприкосновенность произведения, право на обнародование произведения) возникают с момента
								создания, действуют бессрочно, и принадлежат автору.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>9.3.</span>
								Авторские исключительные (имущественные) права на веб-сайт (ст. 1270 ГК РФ) в полном объеме принадлежат
								Администрации, возникают с момента создания веб-сайта, и действуют в течение всей жизни автора и 70 лет
								после смерти автора, исчисляемые в соответствии с законодательством Российской Федерации.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>9.4.</span>
								Все представленные Пользователю результаты интеллектуальной деятельности защищены авторским правом и
								охраняются законом во всех формах, на любых носителях и в отношении любых технологий, как существующих в
								настоящее время, так и разработанных либо созданных впоследствии.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>9.5.</span>
								Пользователю признает и соглашается с тем, что в рамках Договора ему предоставляется доступ и право
								использования интеллектуальной собственности, не находящейся в открытом доступе, правообладателем
								которой на законных основаниях является Администрация.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>9.6.</span>В процессе взаимодействия сторон по Договору, отчуждение
								авторских исключительных (имущественных) прав на любые представленные объекты авторского права от
								Администрации к Пользователю не происходит.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>9.7.</span>
								Пользователь обязан воздерживаться от любых действий, которые нарушают или могут нарушить авторские
								права Администрации, в частности, не копировать, не записывать, не транслировать, не распространять
								объекты авторского права без письменного разрешения.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>9.8.</span>
								Пользователь имеет право использовать объекты авторского права, правообладателем которых является
								Администрация, только тем способом, который разрешен Договором, в пределах функциональных возможностей
								онлайн-сервиса и предоставленных прав доступа выбранного Тарифного плана.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>9.9.</span>
								Пользователь обязан немедленно сообщать о любых ставших известными фактах нарушения исключительных прав
								Администрации. Запрещается любая переработка, запись, копирование онлайн-сервиса либо его отдельных
								элементов, перевод на иностранный язык, переработка в программу для ЭВМ, а также создание новых
								результатов интеллектуальной деятельности, производных от веб-сайта, без получения специального
								(письменного) разрешения Администрации.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>10. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ ИЛИ РАСТОРЖЕНИЯ ДОГОВОРА</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>10.1.</span>
								Администрация имеет право в одностороннем порядке вносить изменения или дополнения в Публичную оферту
								путем внесения изменений к текущей редакции либо путем утверждения новой редакции.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>10.2.</span>
								Администрация обязана уведомить Пользователя о внесении изменений или утверждении новой редакции
								Публичной оферты в день их вступления в силу, путем публикации указанных изменений на веб-сайте или
								отправки уведомления на адрес электронной почты.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>10.3.</span>
								Новая редакция Публичной оферты распространяется на всех Пользователей, в т. ч. совершивших акцепт ранее
								даты вступления указанных изменений в силу. При этом, стороны понимают и соглашаются с тем, что
								указанные изменения применяются к ним исключительно в той части правоотношений, которые возникнут после
								их вступления в силу.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>10.4.</span>
								Если Пользователь продолжает совершать встречные действия после внесения изменений в Публичную оферту,
								подразумевается, что такие встречные действия выражают его полное и безоговорочное согласие с Публичной
								офертой в новой редакции, на основании ранее совершенного им акцепта.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>10.5.</span>
								Договор может быть расторгнут по следующим основаниям:
								<ul className='list-none m-0 p-0 pl-5'>
									<li>
										<span className='mr-1 font-bold '>10.5.1.</span>
										По соглашению сторон.
									</li>
									<li>
										<span className='mr-1 font-bold '>10.5.2.</span>
										По инициативе Пользователя в одностороннем (внесудебном) порядке.
									</li>
									<li>
										<span className='mr-1 font-bold '>10.5.3.</span>
										По инициативе Администрации в одностороннем (внесудебном) порядке, в случае существенного нарушения
										Пользователем условий Договора или Пользовательского соглашения, а также в иных случаях,
										предусмотренных действующим законодательством Российской Федерации.
									</li>
								</ul>
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>10.6.</span>Договор прекращается на следующий день после его
								расторжения или окончания срока действия Тарифного плана, если иное не установлено соглашением сторон
								или решением суда.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>10.7.</span>
								По требованию одной из сторон Договор может быть изменен или расторгнут по решению суда только: 1) при
								существенном нарушении Договора другой стороной; 2) в иных случаях, предусмотренных ГК РФ или другими
								законами. Существенным признается нарушение, которое влечет такой ущерб для другой стороны, что она в
								значительной степени лишается того, на что рассчитывала при заключении Договора.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>11. ОБМЕН ЮРИДИЧЕСКИ ЗНАЧИМЫМИ СООБЩЕНИЯМИ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>11.1.</span>
								Обмен юридически значимыми сообщениями связанными с возникновением, изменением или прекращением
								обязательств, основанных на Договоре, либо содержащих иную информацию, имеющую правовое значение для
								сторон, направляются сторонами друг другу исключительно с помощью способов и средств связи,
								предусмотренных настоящим разделом Договора, с использованием реквизитов, указанных в Договоре, на
								веб-сайте, содержащихся в ЕГРЮЛ (ЕГРИП), а также переданных Пользователем в момент совершения акцепта.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>11.2.</span>
								Соблюдение вышеуказанных требований достоверно свидетельствует о совершении этих действий конкретной
								стороной, имеет юридическую силу, может быть использовано в качестве доказательств в суде и является
								неотъемлемой частью Договора.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>11.3.</span>
								Стороны согласовали следующие возможные способы и средства связи для обмена документами и иными
								юридически значимыми сообщениями: электронная почта, мессенджер Telegram, привязанный к контактному
								телефону (мобильному). Днем получения документов и иных юридически значимых сообщений является день их
								отправки.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>11.4.</span>
								Отправка электронных документов предполагает отправку документов как без их предварительного
								документирования на бумажном носителе либо документов, изготовленных на бумажном носителе, переведенных
								в электронную форму с помощью средств сканирования), заверенных простой электронной подписью. Набранный
								текст ("тело) электронного сообщения также признается сторонами электронным документом.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>11.5.</span>
								Стороны обязуются сохранять конфиденциальность доступа к собственным средствам связи, не передавать
								доступ третьим (неуполномоченным) лицам, и принимают на себя обязательства предпринимать все необходимые
								и достаточные меры защиты во избежание несанкционированного доступа.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>11.6.</span>Стороны обязуются незамедлительно уведомлять друг друга
								обо всех случаях компрометации собственных средств связи, а также о невозможности открытия или просмотра
								файлов по причине их повреждения или иным причинам, не зависящим от адресата. В отсутствие такого
								уведомления документы и иные юридически значимые сообщения, направленные стороной с учетом имеющейся у
								адресата информации, признаются надлежащими.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h6>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>12.1.</span>
								Публичная оферта содержит весь объем договоренностей между сторонами в отношении предмета, срока, цены и
								других существенных условий Договора, которые отменяют и делают недействительными все другие
								обязательства или представления, которые могли быть приняты или сделаны сторонами в устной или
								письменной форме до совершения акцепта.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>12.2.</span>
								Стороны не оформляют отдельный Акт приема-передачи предоставления доступа и передачи права использования
								онлайн-сервиса в целях подтверждения исполнения своих обязательств по Договору за исключением случая,
								если одна из сторон заявила соответствующее требование.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>12.3.</span>
								После совершения акцепта все предыдущие устные или письменные соглашения между сторонами признаются
								утратившими свою силу, а стороны руководствуются исключительно условиями Договора. Во всем ином, что не
								предусмотрено Договором, стороны руководствуются законодательством Российской Федерации, а в случае
								отсутствия нормы права, регулирующей возникшие правоотношения, сложившимися обычаями делового оборота
								или научной доктриной.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>12.4.</span>
								Стороны признают, что, если какое-либо из отдельных положений Договора становится недействительным в
								течении срока его действия, вследствие изменения законодательства или на основании решения суда,
								остальные положения Договора являются обязательными для сторон в течении срока действия Договора.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>12.5.</span>В случае изменения у какой-либо из сторон реквизитов,
								инициирующая изменения сторона обязана незамедлительно, но не позднее 5 (пяти) рабочих дней с момента
								изменений, уведомить об этом другую сторону. При этом, стороны соглашаются с тем, что уведомления и
								платежи, совершенные по старым адресам или реквизитам, до поступления уведомления об их изменении,
								считаются должным и надлежащим исполнением.
							</p>
							<p className='mb-2'>
								<span className='mr-1 font-bold '>12.6.</span>Публичная оферта составлена на русском языке, в
								соответствии с законодательством Российской Федерации, опубликована на веб-сайте в свободном доступе, ее
								подлинный экземпляр хранится по местонахождению Администрации.
							</p>
						</section>
						<section className='mb-7 text-sm'>
							<h6 className='ml-1 font-bold mb-8 text-sm'>13. РЕКВИЗИТЫ АДМИНИСТРАЦИИ</h6>
							<table className='flex w-full border border-solid font-bold'>
								<tr className='flex flex-col w-1/3 '>
									<th className='border border-solid p-2 text-start'>Наименование</th>
									<th className='border border-solid p-2 text-start'>ОГРН</th>
									<th className='border border-solid p-2 text-start'>ИНН</th>
									<th className='border border-solid p-2 text-start'>Юридический адрес</th>
									<th className='border border-solid p-2 text-start'>E-mail</th>
									<th className='border border-solid p-2 text-start'>Тел.</th>
								</tr>
								<tr className='flex flex-col w-2/3'>
									<td className='border border-solid p-2'>ООО «ЛАД-Интеллект»</td>
									<td className='border border-solid p-2'>1155260010960</td>
									<td className='border border-solid p-2'>5260414210</td>
									<td className='border border-solid p-2'>
										603093, г. Нижний Новгород, ул. Родионова, д.23А, офис 313
									</td>
									<td className='border border-solid p-2'>support@gptzator.ru</td>
									<td className='border border-solid p-2'>8 (831) 2-333-666</td>
								</tr>
							</table>
						</section>
					</div>
					<Button key='ok' type='primary' size='large' onClick={onConfirmHandler}>
						Закрыть
					</Button>
				</div>
			</Modal>
		);
	}
);
