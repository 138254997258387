import { memo, ReactNode } from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '../Icons';

const InfoPopoverComponent = ({ content }: { content: ReactNode }) => {
	return (
		<Popover content={content}>
			<QuestionCircleOutlined className='cursor-pointer opacity-[0.45]' />
		</Popover>
	);
};

export const InfoPopover = memo(InfoPopoverComponent);
