import { CheckOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { memo, useRef, FC } from 'react';

import { TCardItem } from '../../../api/types';

type TArtefactOptionProps = {
	item: TCardItem;
	onCheckboxChange: (title: string) => void;
	disabled: boolean;
};

const ArtefactOptionComponent: FC<TArtefactOptionProps> = ({ item, onCheckboxChange, disabled }) => {
	const checkRef = useRef<HTMLInputElement | null>(null);

	return (
		<div
			key={item.title}
			className={cn(
				'relative flex items-center justify-between px-5 py-6 border-solid',
				'border rounded-xl border-gray-checkbox w-[248px] min-w-[248px] min-h-[100px] cursor-pointer',
				{
					'bg-primary-bg-hover hover:border-primary-hover': item.checked || false,
					'hover:border-gray-border': !item.checked || false,
					'pointer-events-none bg-primary-bg-hover border-none': disabled && item.checked,
					'pointer-events-none bg-gray-bg border-none': disabled && !item.checked
				}
			)}
			onClick={(event) => {
				event.preventDefault();
				checkRef.current && checkRef.current.click();
			}}
		>
			<div className='flex flex-col gap-y-1'>
				<span
					className={cn({
						'text-primary-font-active': disabled && item.checked,
						'pointer-events-none bg-gray-bg opacity-50': disabled && !item.checked
					})}
				>
					{item.title}
				</span>
				{item.description && <span className='opacity-40 text-sm'>{item.description}</span>}
			</div>

			{!disabled && (
				<label
					className={cn(
						'relative rounded-full min-w-[20px] min-h-[20px] w-[20px] h-[20px] flex items-center justify-center cursor-pointer ml-2.5',
						{ 'bg-gray-checkbox': !item.checked, 'bg-primary': item.checked }
					)}
				>
					<input
						ref={checkRef}
						className='absolute w-0 h-0 opacity-0'
						type='checkbox'
						checked={item.checked}
						onChange={() => onCheckboxChange(item.title)}
					/>
					<CheckOutlined className='text-white text-xs' />
				</label>
			)}
		</div>
	);
};

export const ArtefactOption = memo(ArtefactOptionComponent);
