export const components = {
	Button: {
		colorPrimary: '#89F595',
		colorLink: '#45C16F',
		colorLinkHover: '#A3FFAB',
		colorTextLightSolid: '#1B2631',
		defaultBg: '#1B2631',
		defaultHoverBg: '#1B2631',
		defaultColor: '#FFFFFF',
		defaultHoverColor: '#89F595',
		defaultBorderColor: '#1B2631',
		defaultHoverBorderColor: '#1B2631',
		defaultActiveBg: '#1B2631'
	},
	Table: {
		rowHoverBg: '#e0ffe'
	}
};
