import React, { useMemo } from 'react';
import { getConnectedEdges, Handle, useNodeId, useStore, HandleProps, ReactFlowState } from 'reactflow';

const selector = (s: ReactFlowState) => ({
	nodeInternals: s.nodeInternals,
	edges: s.edges
});

type TLimitedHandle = HandleProps & {
	limit: number;
	className?: string;
};

const LimitedHandle = (props: TLimitedHandle) => {
	const { nodeInternals, edges } = useStore(selector);
	const nodeId = useNodeId();

	const isHandleConnectable = useMemo(() => {
		if (nodeId) {
			const node = nodeInternals.get(nodeId);
			const connectedEdges = node ? getConnectedEdges([node], edges) : [];

			return connectedEdges.length < props.limit;
		}

		return props.isConnectable;
	}, [nodeInternals, edges, nodeId, props.isConnectable]);

	return <Handle {...props} className={props.className} isConnectable={isHandleConnectable}></Handle>;
};

export default LimitedHandle;
