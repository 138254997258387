import { Popover } from 'antd';
import { removeBraces } from '../../../../shared/lib/removeBraces';

export const Annotations = (props: { annotations: Record<string, string> }) => {
	const { annotations } = props;
	const keys = Object.keys(annotations);
	return (
		<div>
			{keys.map((key) => {
				const annotationIndex = removeBraces(key) as string;
				return (
					<Popover
						content={<div className='max-h-[200px] max-w-xl overflow-auto'>{annotations[`${key}`]}</div>}
						key={annotations[`${key}`]?.trim()}
					>
						<span className='text-primary-font cursor-pointer'>{`【${parseInt(annotationIndex, 10)}】`}</span>
					</Popover>
				);
			})}
		</div>
	);
};
