import { ReactNode } from 'react';
import { EOauthClientType } from 'shared';
import { LockOutlined, GoogleCircleFilled } from '../ui/Icons';

export const getOauthClientLogo = (clientType: EOauthClientType, className?: string): ReactNode => {
	switch (clientType) {
		case EOauthClientType.HH:
			// TODO replace to HH icon ?
			return <LockOutlined className={className} />;
		case EOauthClientType.GOOGLE:
			return <GoogleCircleFilled className={`opacity-40 ${className}`} />;
		default:
			return <LockOutlined className={className} />;
	}
};
