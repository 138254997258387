import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { TThreadDTO } from '../api/type';
import { threadApi } from '../api/threadApi';
import { TMessage } from './types';

type TThreadState = {
	thread: TThreadDTO | null;
	messages: TMessage[];
	totalMessages: number;
	isImgViewerOpen: boolean;
	openUploadFiles: boolean;
	selectedImg: string | null;
};

const initialState: TThreadState = {
	thread: null,
	messages: [],
	totalMessages: 0,
	isImgViewerOpen: false,
	openUploadFiles: false,
	selectedImg: null
};

export const threadSlice = createSlice({
	name: 'thread',
	initialState,
	reducers: {
		setIsImgViewerOpen: (state, action: PayloadAction<boolean>) => {
			state.isImgViewerOpen = action.payload;
		},
		setOpenUploadFiles: (state, action: PayloadAction<boolean>) => {
			state.openUploadFiles = action.payload;
		},
		setSelectedImg: (state, action: PayloadAction<string | null>) => {
			state.selectedImg = action.payload;
		},
		deleteMessage: (state, action: PayloadAction<string>) => {
			state.messages = state.messages.filter((message) => message.id !== action.payload);
		},
		editMessage: (state, action: PayloadAction<{ id: string; newText: string }>) => {
			state.messages = state.messages.map((message) => {
				if (message.id === action.payload.id && message.choices[0]) {
					message.choices[0] = {
						...message.choices[0],
						content: action.payload.newText
					};
					return message;
				} else {
					return message;
				}
			});
		},
		setMessagesList: (state, action: PayloadAction<{ messages: TMessage[]; totalDocs: number }>) => {
			state.messages = action.payload.messages;
			state.totalMessages = action.payload.totalDocs;
		},
		updateMessagesList: (state, action: PayloadAction<{ message: TMessage[]; totalDocs: number }>) => {
			state.messages = [...state.messages, ...action.payload.message];
			state.totalMessages = action.payload.totalDocs;
		},
		resetThread: () => {
			return { ...initialState };
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(threadApi.endpoints.getThreadById.matchFulfilled, (state, action: PayloadAction<TThreadDTO>) => {
			return {
				...state,
				thread: action.payload
			};
		});
	}
});

export const {
	resetThread,
	updateMessagesList,
	setMessagesList,
	deleteMessage,
	editMessage,
	setIsImgViewerOpen,
	setOpenUploadFiles,
	setSelectedImg
} = threadSlice.actions;
