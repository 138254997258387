import { STYLES } from 'shared';
import { Step } from 'react-joyride';
import { getLocale } from 'shared/lib/i18n';

const locale = getLocale();

export const choiceControls: Step = {
	content:
		locale === 'en'
			? 'Here you can change the selected options, generate the step result again and evaluate the step'
			: 'Здесь вы можете изменить выбранные варианты, сгенерировать результат шага заново или отправить отзыв',
	disableBeacon: true,
	disableOverlayClose: true,
	hideCloseButton: false,
	showSkipButton: false,
	showProgress: false,
	hideBackButton: true,
	placement: 'auto',
	styles: {
		options: {
			zIndex: 10000,
			arrowColor: STYLES.colors.dark
		}
	},
	target: `.choice-artefact-controls`,
	title: locale === 'en' ? 'Step control' : 'Управление шагом'
};

export const dynamicControls: Step = {
	content:
		locale === 'en'
			? 'Here you can edit the result, copy the step result, generate the step result again and evaluate the step'
			: 'Здесь вы можете работать с результатом: редактировать, копировать, сгенерировать результат заново или отправить отзыв',
	disableBeacon: true,
	disableOverlayClose: true,
	hideCloseButton: false,
	showSkipButton: false,
	showProgress: false,
	hideBackButton: true,
	placement: 'auto',
	styles: {
		options: {
			zIndex: 10000,
			arrowColor: STYLES.colors.dark
		}
	},
	target: `.dynamic-artefact-controls`,
	title: locale === 'en' ? 'Step control' : 'Управление шагом'
};

export const progressTooltip: Step = {
	content:
		locale === 'en'
			? 'Here you can see a list of all the steps and the progress of the project creation'
			: 'Здесь вы можете видеть список всех шагов и прогресс работы навыка',
	disableBeacon: true,
	disableOverlayClose: true,
	hideCloseButton: false,
	showSkipButton: false,
	showProgress: false,
	hideBackButton: true,
	placement: 'left-start',
	styles: {
		options: {
			zIndex: 10000,
			arrowColor: STYLES.colors.dark
		}
	},
	target: '.project-progress',
	title: locale === 'en' ? 'Project creation progress' : 'Ход работы навыка'
};

export const finishTooltip: Step = {
	content:
		locale === 'en'
			? 'Read the result of the app and click ‘Back’ to get started with your request'
			: 'Ознакомьтесь с результатом выполнения навыка и нажмите "Назад", чтобы начать работу со своим запросом',
	disableBeacon: true,
	disableOverlayClose: true,
	hideCloseButton: true,
	showSkipButton: false,
	showProgress: false,
	hideBackButton: true,
	placement: 'center',
	styles: {
		options: {
			zIndex: 10000,
			arrowColor: STYLES.colors.dark
		}
	},
	target: 'body',
	title: locale === 'en' ? 'Result' : 'Результат'
};

export const newProjectRequestField: Step = {
	content: locale === 'en' ? 'Enter the text of your request and...' : 'Вводим запрос, например такой, и...',
	disableBeacon: true,
	disableOverlayClose: true,
	hideCloseButton: false,
	showSkipButton: false,
	placement: 'auto',
	styles: {
		options: {
			arrowColor: STYLES.colors.dark
		}
	},
	target: `.request-text`
};

export const createProjectBtn: Step = {
	content:
		locale === 'en' ? '...click the button to start creating the project' : '...нажимаем кнопку, чтобы запустить навык',
	disableBeacon: true,
	disableOverlayClose: true,
	hideCloseButton: false,
	showSkipButton: false,
	hideBackButton: true,
	hideFooter: true,
	placement: 'auto',
	spotlightClicks: true,
	styles: {
		options: {
			arrowColor: STYLES.colors.dark
		}
	},
	target: `.submit-btn`
};
