import { memo } from 'react';
import { EAppFormFields, TAppFormFields } from '../../../model/types';
import { Form, Input, Select, Switch } from 'antd';
import { LabelWithInfo } from 'shared/ui/LabelWithInfo';
import { validateNotEmpty } from 'shared/lib';
import { useTranslate } from 'shared/lib/i18n';
import { ApiTemplateSelect } from '../../ApiTemplateSelect';
import { useGetApiTemplateQuery } from 'entities/apiTemplates';

const ApiTemplateCallFieldsComponent = ({ action, title, actionsSlot, onChange, path }: TAppFormFields) => {
	const t = useTranslate();
	const { paramsFields } = useGetApiTemplateQuery(action.data[EAppFormFields.TEMPLATE], {
		selectFromResult: ({ data }) => {
			return {
				paramsFields: data?.params
			};
		},
		skip: !action.data[EAppFormFields.TEMPLATE]
	});

	return (
		<div className={action.selected ? '' : 'hidden'}>
			<div className='flex justify-between text-base font-medium'>
				{title}
				{actionsSlot}
			</div>
			<Form.Item
				label={EAppFormFields.NEXT_SLUG}
				name={[...path, EAppFormFields.NEXT_SLUG]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.BLOCK_TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
				name={[...path, EAppFormFields.BLOCK_NAME]}
				initialValue={action.data[EAppFormFields.BLOCK_NAME]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('dynamic_name')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('dynamic_name.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.SLUG]}
				initialValue={action.data[EAppFormFields.SLUG]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('description')}</span>}
				name={[...path, EAppFormFields.DESCRIPTION]}
				initialValue={action.data[EAppFormFields.DESCRIPTION]}
				rules={[
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 2, maxRows: 4 }}
					onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
				/>
			</Form.Item>
			<ApiTemplateSelect
				name={[...path, EAppFormFields.TEMPLATE]}
				initialValue={action.data[EAppFormFields.TEMPLATE]}
				onChange={(value) => onChange([...path, EAppFormFields.TEMPLATE], value)}
			/>
			<div>
				<div className='mb-2 font-medium text-black'>{t('params')}:</div>
				{paramsFields?.map((field, index) => {
					switch (field.blockType) {
						case 'text':
							return (
								<Form.Item
									key={field.name}
									name={[...path, EAppFormFields.PARAMS, field.name]}
									label={
										field.blockName ? (
											<div className='text-start max-w-[185px] min-w-[185px] text-ellipsis overflow-hidden'>
												<span className='max-w-full whitespace-normal opacity-60'>{field.blockName}</span>
												<span className='ml-1 text-error'>*</span>
											</div>
										) : null
									}
									rules={[{ required: true, message: t('error.required_field') }]}
									initialValue={action.data[EAppFormFields.PARAMS]?.[field.name]}
								>
									<Input />
								</Form.Item>
							);
						case 'textarea':
							return (
								<Form.Item
									key={field.name}
									name={[...path, EAppFormFields.PARAMS, field.name]}
									label={
										field.blockName ? (
											<div className='text-start max-w-[185px] min-w-[185px] text-ellipsis overflow-hidden'>
												<span className='max-w-full whitespace-normal opacity-60'>{field.blockName}</span>
												<span className='ml-1 text-error'>*</span>
											</div>
										) : null
									}
									rules={[{ required: true, message: t('error.required_field') }]}
									initialValue={action.data[EAppFormFields.PARAMS]?.[field.name]}
								>
									<Input.TextArea size='large' autoSize={{ minRows: 1, maxRows: 3 }} />
								</Form.Item>
							);
						case 'select':
							return (
								<Form.Item
									key={field.name}
									name={[...path, EAppFormFields.PARAMS, field.name]}
									label={
										field.blockName ? (
											<div className='text-start max-w-[185px] min-w-[185px] text-ellipsis overflow-hidden'>
												<span className='max-w-full whitespace-normal opacity-60'>{field.blockName}</span>
												<span className='ml-1 text-error'>*</span>
											</div>
										) : null
									}
									rules={[{ required: true, message: t('error.required_field') }]}
									initialValue={action.data[EAppFormFields.PARAMS]?.[field.name]}
								>
									<Select options={field.options} />
								</Form.Item>
							);
						default:
							return null;
					}
				})}
			</div>
			<Form.Item
				label={
					<LabelWithInfo
						label={<span>{t('visibility')}</span>}
						tooltipText={t('visibility.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.IS_HIDDEN]}
				initialValue={action.data[EAppFormFields.IS_HIDDEN]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo label={<span>{t('autonext')}</span>} tooltipText={t('autonext.description')}></LabelWithInfo>
				}
				name={[...path, EAppFormFields.AUTONEXT]}
				initialValue={action.data[EAppFormFields.AUTONEXT]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
			</Form.Item>
		</div>
	);
};

export const ApiTemplateCallFields = memo(ApiTemplateCallFieldsComponent);
