export const selectCurrentThread = (state: RootState) => {
	return state.thread;
};

export const selectIsGeneratingThread = (state: RootState) => {
	return state.thread.thread?.status === 'generating';
};

export const selectThreadHeader = (state: RootState) => {
	return { title: state.thread.thread?.title || '', description: state.thread.thread?.model?.name || '' };
};
