import { baseApi } from 'shared/api/baseApi';
import { axiosInstance } from 'shared/api/axiosInstance';
import qs from 'qs';
import { FILE_TAG, FILES_TAG } from 'shared/api/tags';
import { TFile, TFileDTO } from './types';

export const uploadFile = async (file: File) => {
	const data = await axiosInstance.post(
		`files`,
		{ _payload: JSON.stringify({ uploadToGPT: true }), file: file },
		{
			withCredentials: true,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
	return data.data;
};

export const fileApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		uploadFile: build.mutation<any, { formData: FormData }>({
			query: ({ formData }) => {
				return {
					url: `files`,
					method: 'POST',
					data: formData
				};
			}
		}),
		getFile: build.query<TFile, string>({
			query: (id) => ({
				url: `files/${id}`
			}),
			providesTags: [FILE_TAG]
		}),
		getFilesByIds: build.query<TFileDTO, string[]>({
			query: (ids) => {
				const stringifiedQuery = qs.stringify(
					{
						where: {
							id: {
								in: ids
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `files${stringifiedQuery}`
				};
			},
			providesTags: [FILES_TAG]
		}),
		deleteFile: build.mutation<any, { fileId: string }>({
			query: ({ fileId }) => {
				const stringifiedQuery = qs.stringify(
					{
						where: {
							id: {
								equals: fileId
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `files${stringifiedQuery}`,
					method: 'DELETE'
				};
			}
		}),
		uploadThreadFile: build.mutation<any, { formData: FormData }>({
			query: ({ formData }) => {
				return {
					url: `threads_files`,
					method: 'POST',
					data: formData
				};
			}
		}),
		deleteThreadFile: build.mutation<any, { fileId: string }>({
			query: ({ fileId }) => {
				const stringifiedQuery = qs.stringify(
					{
						where: {
							id: {
								equals: fileId
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `threads_files${stringifiedQuery}`,
					method: 'DELETE'
				};
			}
		})
	})
});

export const {
	useUploadThreadFileMutation,
	useDeleteThreadFileMutation,
	useDeleteFileMutation,
	useUploadFileMutation,
	useGetFileQuery,
	useGetFilesByIdsQuery,
	useLazyGetFileQuery
} = fileApi;
