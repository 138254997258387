import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { TApp, TFilterParams } from './types';
import { appsApi } from '../api/appsApi';
import { TTagsDTO } from '../api/types';

type TAppsState = {
	apps: TApp[];
	filter: TFilterParams;
	page: number;
	tags: { label: string; value?: string }[];
};

const initialState: TAppsState = {
	apps: [],
	filter: {},
	page: 1,
	tags: []
};

export const appsSlice = createSlice({
	name: 'apps',
	initialState,
	reducers: {
		setAppsList: (state, action: PayloadAction<TApp[]>) => {
			state.apps = action.payload;
		},
		setPage: (state, action: PayloadAction<number>) => {
			state.page = action.payload;
		},
		setFilter: (state, action: PayloadAction<TFilterParams>) => {
			state.filter = { ...action.payload };
			state.page = 1;
		},
		updateAppsList: (state, action: PayloadAction<TApp[]>) => {
			state.apps = [...state.apps, ...action.payload];
		},
		updateAppIsFavourite: (state, action: PayloadAction<{ id: string; isFavourite: boolean }>) => {
			const app = state.apps.find((app) => app.id === action.payload.id);
			if (app) {
				app.isFavourite = action.payload.isFavourite;
			}
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(appsApi.endpoints.getTags.matchFulfilled, (state, action: PayloadAction<TTagsDTO>) => {
			state.tags = [{ label: 'Все' }, ...action.payload.docs.map((tag) => ({ label: tag.name, value: tag.id }))];
		});
	}
});

export const { setAppsList, updateAppsList, updateAppIsFavourite, setPage, setFilter } = appsSlice.actions;
