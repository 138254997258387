import { memo, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFilled, WarningFilled } from 'shared/ui/Icons';
import { Popover } from 'antd';
import { useTranslate } from '../../lib/i18n';

interface IOauthClientBtn {
	clientName: string;
	oauthUrl: string;
	logo?: ReactNode;
	hasLogged: boolean;
}

const OauthClientBtnComponent = ({ clientName, oauthUrl, logo, hasLogged }: IOauthClientBtn) => {
	const t = useTranslate();
	const [open, setOpen] = useState(false);

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	return (
		<Popover
			content={<span className='text-white'>{t('auth_required')}</span>}
			color='black'
			trigger='hover'
			destroyTooltipOnHide
			open={open && !hasLogged}
			onOpenChange={handleOpenChange}
		>
			<a
				className={`p-3 bg-fill-alter min-h-[50px] flex gap-x-2 items-center rounded-xl cursor-pointer border border-solid ${
					hasLogged ? 'border-primary-active pointer-events-none' : 'border-warning'
				}`}
				href={oauthUrl}
			>
				{logo}
				<div className='flex gap-y-1 flex-col overflow-hidden'>
					<span className='text-sm max-w-full overflow-hidden text-ellipsis'>{clientName}</span>
				</div>
				{hasLogged ? <CheckCircleFilled className='text-primary' /> : <WarningFilled className='text-warning' />}
			</a>
		</Popover>
	);
};

export const OauthClientBtn = memo(OauthClientBtnComponent);
