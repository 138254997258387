import React from 'react';
import { Error } from '../../../lib/getImg';
import { Button } from 'antd';
import { useTranslate } from '../../../shared/lib/i18n';

export const ErrorFallback = () => {
	const t = useTranslate();
	const onReloadHandler = () => {
		if (typeof window !== 'undefined') {
			window.location.reload();
		}
	};
	return (
		<div className='max-h-full overflow-y-auto w-full overflow-y-auto flex flex-wrap items-center justify-center gap-x-24'>
			<div>
				<h1 className='text-7xl font-bold uppercase'>{t('error')}</h1>
				<p className='text-2xl opacity-60 my-6'>{t('something_wrong')}...</p>
				<Button type='primary' onClick={onReloadHandler} size='large'>
					{t('reload_page')}
				</Button>
			</div>
			<div>
				<img className='w-full rounded object-none' src={Error} alt='' />
			</div>
		</div>
	);
};
