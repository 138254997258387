import React, { memo, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../shared/model';
import { selectCurrentProject } from '../../model/selectors';
import { mdToHtml } from '../../../../shared/lib/mdToHtml';
import cx from './ProjectData.module.css';

const ProjectDataComponent = () => {
	const [generatedHtml, setGeneratedHtml] = useState<string>('');
	const { project } = useAppSelector(selectCurrentProject);
	const createHtmlStr = async (value: any) => {
		const listViewNormalizedValue =
			typeof value === 'string'
				? value.replace(/\n\s*\d+(\.\d+)+\.\s/g, '\n\n$&').replace(/\n(\d+\.) /g, '\n\n$1 ')
				: JSON.stringify(value, null, 2);
		const htmlStr = await mdToHtml(`\`\`\`json\n${listViewNormalizedValue}\n\`\`\``);
		setGeneratedHtml(htmlStr);
	};

	useEffect(() => {
		const artefactsData = project?.artefacts.map((artefact) => ({ [artefact.slug ?? artefact.name]: artefact.data }));
		createHtmlStr(artefactsData);
	}, [project]);

	return (
		<div className={cx.card}>
			<div dangerouslySetInnerHTML={{ __html: generatedHtml }} />
		</div>
	);
};

export const ProjectData = memo(ProjectDataComponent);
