import { baseApi } from 'shared/api/baseApi';
import {
	GENERATION_TYPES_TAG,
	MODELS_LIST_TAG,
	THREAD_MESSAGES_TAG,
	THREAD_TAG,
	THREADS_LIST_TAG
} from 'shared/api/tags';
import { TMessage } from '../model/types';
import { TThreadsDTO, TThreadDTO, TMessagesDTO, TThreadsSearchParams, TGenerationTypeDTO } from './type';
import qs from 'qs';
import { MESSAGE_PER_PAGE, THREADS_PER_PAGE } from '../model/constants';
import { setMessagesList, updateMessagesList } from '../model/slice';

export const threadApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getThreads: build.query<TThreadsDTO, TThreadsSearchParams | undefined>({
			query: (params) => {
				if (!params) {
					return {
						url: `threads`
					};
				}
				let query;
				if (params.search) {
					query = {
						title: {
							contains: params.search
						}
					};
				}

				const stringifiedQuery = qs.stringify(
					{
						...(query ? { where: query } : {}),
						limit: THREADS_PER_PAGE,
						page: params.page
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `threads${stringifiedQuery}`
				};
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			},
			merge: (currentCache, newItems, { arg }) => {
				if (arg && arg.page !== 1) {
					return {
						...currentCache,
						...newItems,
						docs: [...currentCache.docs, ...newItems.docs]
					};
					currentCache.docs.push(...newItems.docs);
				}
				return newItems;
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: [THREADS_LIST_TAG]
		}),
		getThreadById: build.query<TThreadDTO, string>({
			query: (id) => ({
				url: `threads/${id}`
			}),
			providesTags: [THREAD_TAG]
		}),
		createThread: build.mutation<TThreadDTO, { title: string; id: string; vaultIds?: string[]; fileIds?: string[] }>({
			query: ({ id, title, vaultIds, fileIds }) => {
				return {
					url: 'threads/create',
					method: 'POST',
					data: {
						title,
						...(vaultIds?.length ? { vaultIds } : {}),
						...(fileIds?.length ? { fileIds } : {}),
						modelId: id
					}
				};
			},
			invalidatesTags: [MODELS_LIST_TAG]
		}),
		deleteThread: build.mutation<TThreadDTO, string>({
			query: (id) => {
				const stringifiedQuery = qs.stringify(
					{
						where: {
							id: {
								equals: id
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `threads${stringifiedQuery}`,
					method: 'DELETE'
				};
			},
			onQueryStarted: async (payload, { dispatch, queryFulfilled }) => {
				try {
					await queryFulfilled;
					await dispatch(threadApi.endpoints.getThreads.initiate(undefined));
				} catch (err) {
					console.error(err);
				}
			},
			invalidatesTags: [THREADS_LIST_TAG]
		}),
		updateThreadVault: build.mutation<TThreadDTO, { id: string; vaultIds: string[] }>({
			query: ({ id, vaultIds }) => {
				return {
					url: `threads/${id}/set-context`,
					method: 'POST',
					data: {
						vaultIds
					}
				};
			},
			invalidatesTags: [MODELS_LIST_TAG]
		}),
		getMessages: build.query<TMessagesDTO, { threadId: string; page?: number }>({
			query: ({ threadId, page = 1 }) => {
				const query = {
					thread: {
						equals: threadId
					}
				};

				const stringifiedQuery = qs.stringify(
					{
						where: query,
						limit: MESSAGE_PER_PAGE,
						page
					},
					{ addQueryPrefix: true }
				);

				return {
					url: `threads_messages${stringifiedQuery}`
				};
			},
			onQueryStarted: async (payload, { dispatch, queryFulfilled }) => {
				const { data } = await queryFulfilled;
				if (payload.page !== 1) {
					dispatch(updateMessagesList({ message: data.docs, totalDocs: data.totalDocs }));
				} else {
					dispatch(setMessagesList({ messages: data.docs, totalDocs: data.totalDocs }));
				}
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			},
			merge: (currentCache, newItems, { arg }) => {
				if (arg.page !== 1) {
					return {
						...currentCache,
						...newItems,
						docs: [...currentCache.docs, ...newItems.docs]
					};
					currentCache.docs.push(...newItems.docs);
				}
				return newItems;
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: [THREAD_MESSAGES_TAG]
		}),
		getGenerationTypes: build.query<TGenerationTypeDTO, void>({
			query: () => {
				return {
					url: `threads_generation_types`
				};
			},
			providesTags: [GENERATION_TYPES_TAG]
		}),
		updateGenerationType: build.mutation<TThreadDTO, { threadId: string; typeId: string }>({
			query: ({ threadId, typeId }) => {
				return {
					url: `threads/${threadId}`,
					method: 'PATCH',
					data: { generationType: typeId }
				};
			},
			invalidatesTags: [GENERATION_TYPES_TAG, THREAD_TAG]
		}),
		updateThreadModel: build.mutation<TThreadDTO, { threadId: string; modelId: string }>({
			query: ({ threadId, modelId }) => {
				return {
					url: `threads/${threadId}`,
					method: 'PATCH',
					data: { model: modelId }
				};
			},
			invalidatesTags: [THREAD_TAG]
		}),
		createMessage: build.mutation<TMessage, { text: string; threadId: string }>({
			query: ({ text, threadId }) => {
				return {
					url: `threads/${threadId}/messages`,
					method: 'POST',
					data: {
						text
					}
				};
			},
			invalidatesTags: [THREAD_TAG, THREAD_MESSAGES_TAG]
		}),
		editMessage: build.mutation<TMessage, { content: string; messageId: string; threadId: string }>({
			query: ({ content, threadId, messageId }) => {
				return {
					url: `threads/${threadId}/messages/${messageId}`,
					method: 'PATCH',
					data: {
						content
					}
				};
			}
		}),
		deleteMessage: build.mutation<TMessage, { messageId: string; threadId: string }>({
			query: ({ threadId, messageId }) => {
				return {
					url: `threads/${threadId}/messages/${messageId}`,
					method: 'DELETE'
				};
			}
		}),
		regenerateMessage: build.mutation<TMessage, { messageId: string; threadId: string }>({
			query: ({ threadId, messageId }) => {
				return {
					url: `threads/${threadId}/messages/${messageId}/regenerate`,
					method: 'POST'
				};
			}
		}),
		attachFilesToThread: build.mutation<TMessage, { threadId: string; fileIds: string[] }>({
			query: ({ threadId, fileIds }) => {
				return {
					url: `threads/${threadId}/files/attach`,
					method: 'POST',
					data: {
						fileIds
					}
				};
			},
			invalidatesTags: [THREAD_TAG, THREAD_MESSAGES_TAG]
		})
	})
});

export const {
	useGetThreadByIdQuery,
	useGetThreadsQuery,
	useGetMessagesQuery,
	useCreateThreadMutation,
	useCreateMessageMutation,
	useEditMessageMutation,
	useDeleteMessageMutation,
	useRegenerateMessageMutation,
	useAttachFilesToThreadMutation,
	useUpdateThreadVaultMutation,
	useDeleteThreadMutation,
	useGetGenerationTypesQuery,
	useUpdateGenerationTypeMutation,
	useUpdateThreadModelMutation
} = threadApi;
