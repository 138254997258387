import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { TActionData } from '../../model/types';
import cn from 'classnames';
import LimitedHandle from '../CustomHandle';
import { useTranslate } from '../../../../shared/lib/i18n';

const IdeaActionComponent = (props: NodeProps<TActionData>) => {
	const t = useTranslate();
	const { data, selected } = props;
	return (
		<div
			className={cn(
				'p-4 rounded-md rounded-tl-none bg-white border border-solid border-gray-border min-w-[276px] max-w-[276px] min-h-[14px] relative ',
				{ 'border-primary-active border-2': selected }
			)}
		>
			<div className='absolute -left-[2px] -top-[25px] text-xs rounded-t-lg bg-primary-active px-3 py-1'>
				{t('start')}
			</div>
			<div className='flex justify-between items-center'>
				<div className='text-sm overflow-hidden text-ellipsis whitespace-nowrap'>{data.placeholder}</div>
				<div className='text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis'>
					{data.slug}
				</div>
			</div>

			<LimitedHandle
				type='source'
				position={Position.Bottom}
				className='!rounded-full !border-solid !border-primary !border-2 !w-2 !h-2 !bg-white !-bottom-[5px]'
				limit={1}
			/>
		</div>
	);
};

export const IdeaAction = memo(IdeaActionComponent);
